import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCommentsDollar, faCommentDollar, faInfoCircle, faUser, faTimes, faSearchDollar, faMoneyBillAlt, faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import SubMenu from "./SubMenu";
import SubMenuLTE from "./SubMenuLTE";
import { Nav, Button, Container } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import classNames from "classnames";
import { ReactComponent as VoluumLogoIcon } from '../../assets/img/pw_voluum_logo.svg';

import "./index.scss";

class SideBar extends React.Component {
  render() {
    return (
      <div className={classNames("sidebar", { "is-open": this.props.isOpen })}>
        <div className="sidebar-header">
          <Button
            variant="link"
            onClick={this.props.toggle}
            style={{ color: "#fff" }}
            className="mt-4"
          >
             <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
          </Button>
          <Container
            fluid
            className="text-center"><VoluumLogoIcon /></Container>
        </div>

        <Nav className="flex-column pt-2">
          <p className="ml-3">MENU</p>
          <Nav.Item className="nav-link">
              <NavLink exact={true} activeClassName='active' to='/' role="button">
                  <FontAwesomeIcon icon={faHome} className="mr-2" />Home
              </NavLink>
          </Nav.Item>

          <Nav.Item className="nav-link">
              <NavLink exact={true} activeClassName='active' to="/campaigns" role="button">
                  <FontAwesomeIcon icon={faCommentsDollar} className="mr-2" />Campaigns
              </NavLink>
          </Nav.Item>

          <SubMenuLTE
            title="Life Time Earnings"
            icon={faHandHoldingUsd}
          />

          <Nav.Item className="nav-link">
              <NavLink exact={true} activeClassName='active' to="/costs" role="button">
                  <FontAwesomeIcon icon={faSearchDollar} className="mr-2" />Costs
              </NavLink>
          </Nav.Item>

          <Nav.Item className="nav-link">
              <NavLink exact={true} activeClassName='active' to="/first-purchases" role="button">
                  <FontAwesomeIcon icon={faMoneyBillAlt} className="mr-2" />First Purchases and Rebilling
              </NavLink>
          </Nav.Item>

          <Nav.Item className="nav-link">
              <NavLink exact={true} activeClassName='active' to="/custom_conversions" role="button">
                  <FontAwesomeIcon icon={faCommentDollar} className="mr-2" />Custom Conversions
              </NavLink>
          </Nav.Item>

          <SubMenu
            title="Other"
            icon={faInfoCircle}
          />

          <Nav.Item className="nav-link">
              <NavLink exact={true} activeClassName='active' to="/user" role="button">
                  <FontAwesomeIcon icon={faUser} className="mr-2" />User Detail
              </NavLink>
          </Nav.Item>

          {

          /*<Nav.Item>
            <Nav.Link href="/">
              <FontAwesomeIcon icon={faQuestion} className="mr-2" />
              FAQ
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              Contact
            </Nav.Link>
          </Nav.Item>*/}
        </Nav>
      </div>
    );
  }
}

export default SideBar;
