import React, { Component } from "react";
import ConversionsService from "../../services/conversions-service";
import CustomConversionsService from "../../services/custom-conversions-service";
import CampaignsService from "../../services/campaigns-service";
import TFService from "../../services/traffic-sources-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty, formatPrice, formatNumber } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Row, Badge, Col, Card, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsDollar, faBan } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Pagination from '../../components/pagination'
import Table from './table'
import Form from './form'


class Campaigns extends Component {
    constructor(props) {
        super(props);
        //var tempDate = new Date();
        var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
        //let prevStartDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDay());
        this.state = {
            filters: {
                start_date: yesterday,
                end_date: yesterday,
                search: '',
                pager: {
                    page: '1',
                    items: '9999'
                }
            },
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info', // error, warning, info, success
            data: null,
            campaignData: {
                results: null
            },
            trafficSourcesData: {
                results: null
            },
            typeConversionsData: null,
            selectCampaign: false
        };
    }
    componentDidMount() {
        this.getStats();
        this.getTypeConversions();
        //this.getCampaigns();
        this.getTrafficSources();
    }

    getStats = (e) => {
        this.setState({ data: null });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        ConversionsService.getCampaignConversions( getAuth().token, this.state.filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({
                    data: response.data.message,
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        }).catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        }).then(response => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        })
        //this.logger = new RichLogger("Login");
    }

    getTypeConversions = (e) => {
        this.setState({
            typeConversionsData: null,
        });
        let filters = {
            pager: {
                page: '1',
                items: '9999'
            }
        };
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CustomConversionsService.getCustomConversions( getAuth().token, filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({
                    typeConversionsData: response.data.message,
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        }).catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        }).then(response => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        })
        //this.logger = new RichLogger("Login");
    }

    getCampaigns = (filters) => {
        this.setState({
            campaignData: { results: null},
        });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CampaignsService.getList( getAuth().token, filters )
            .then(response => {
                if(response.data.status !== "Failed") {
                    this.setState({
                        campaignData: response.data.message,
                    })
                } else {
                    this.setState({
                        showAlert: true,
                        alertText: response.data.message,
                        error: true,
                        alertSeverity: 'error' // error, warning, info, success
                    });
                }
        })
        .catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        })
        .then(res => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        });
      //this.logger = new RichLogger("Login");
    }

    getTrafficSources = (e) => {
        this.setState({
            trafficSourcesData: { results: null},
        });
        let filters = {
            pager: {
                page: '1',
                items: '9999'
            }
        };
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        TFService.getList( getAuth().token, filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({
                    trafficSourcesData: response.data.message,
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        })
        .catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        })
        .then(res => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
            console.log(this.state.trafficSourcesData);
        });
      //this.logger = new RichLogger("Login");
    }

    handleOnChange = (e) => {
        this.setState({
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info'
        })
    }

    handleOnTFChange = (e) => {
        if (e.target.value){
            this.setState({ selectCampaign: true });
            let filters = {
                pager: {
                    page: '1',
                    items: '9999',
                },
                search: e.target.value
            };
            // get Campaigns
            this.getCampaigns(filters)
        } else {
            this.setState({ selectCampaign: false });
        }
    }

    /*
    handleChangeDate = (dates) => {
        const [start_date, end_date] = dates;
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                start_date: start_date,
                end_date: end_date,
            },
        })
    }
    */

    handleChangeDate = (dates) => {
        const [start_date, end_date] = dates;
        this.setState({
            filters: {
                start_date: start_date,
                end_date: end_date,
            },
        })
    }

    handleSetStartDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                'start_date': date
            },
        })
    }

    handleSetEndDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                'end_date': date
            },
        })
    }

    paginate = (e) => {
        const { filters } = this.state
        filters.pager.page = e.target.value
        this.getStats()
    }

    handleChangeNumberOfColum = (e) => {
        const { filters } = this.state
        filters.pager.items = e.target.value
        filters.pager.page = 1
        this.getStats()
    }

    nextPage = (page) => {
        const { filters } = this.state
        page++
        if(page <= this.state.data.pager.pages) {
            filters.pager.page = page
            this.getStats()
        }
    }

    prevPage = (page) => {
        const { filters } = this.state
        page--
        if(page > 0) {
            filters.pager.page = page
            this.getStats()
        }
    }

    handleChangeRang = (e) => {
        const { filters } = this.state
        const { value } = e.target
        switch(value){
            case '1':
                let yesteday = new Date(Date.now() - 86400000);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': yesteday,
                    'end_date': yesteday
                    }
                })
                break
            case '2':
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': new Date(new Date().setDate(new Date().getDate() - 2)),
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    }
                })
                break
            case '3':
                let last7days = new Date(new Date().setDate(new Date().getDate() - 7));
                this.setState({
                    filters: {
                      ...filters,
                      'start_date': last7days,
                      'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    },
                  })
                  break
            case '4':
                let last30days = new Date(new Date().setDate(new Date().getDate() - 30));
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': last30days,
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    },
                })
                break
            case '5':
                let currenMonth = new Date()
                let firstDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth(), 1);
                let lastDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth() + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfMonth,
                    'end_date': lastDayOfMonth
                    }
                })
                break
            case '6':
                let lastMonth = new Date()
                let firstDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1), 1);
                let lastDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1) + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfLastMonth,
                    'end_date': lastDayOfLastMonth
                    },
                })
                break
            case '7':
                let firstMonthOfYear = new Date(new Date().getFullYear(), 0, 1);
                let lastMonthOfYear = new Date(new Date().getFullYear(), 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfYear,
                    'end_date': lastMonthOfYear
                    },
                })
                break
            case '8':
                let firstMonthOfLastYear = new Date(new Date().getFullYear() -1, 0, 1);
                let lastMonthOfLastYear = new Date(new Date().getFullYear() -1, 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfLastYear,
                    'end_date': lastMonthOfLastYear
                    },
                })
                break
            default:
        }
    }

    render() {
        const { data, filters, campaignData, trafficSourcesData, typeConversionsData } = this.state
        return (
            <Layout>
                <Breadcrumb>
                    <NavLink exact={true} activeClassName='active' to="/campaigns" role="button">
                        <FontAwesomeIcon icon={faCommentsDollar} className="mr-2" />Campaigns
                    </NavLink>
                </Breadcrumb>
                <Form
                    handleOnChange={this.handleOnChange}
                    handleChangeDate={this.handleChangeDate}
                    handleChangeRang={this.handleChangeRang}
                    getStats={this.getStats}
                    //typeConversionsData={typeConversionsData.results}
                    campaignData={campaignData.results}
                    trafficSourcesData={trafficSourcesData.results}
                    handleOnTFChange={this.handleOnTFChange}
                    selectCampaign={this.state.selectCampaign}
                    start_date={filters.start_date}
                    end_date={filters.end_date}
                    yesterday={new Date(new Date().setDate(new Date().getDate()-1))}
                />

                <hr />
                { (this.props.loading) &&
                    <Loading />
                }
                {
                    (!isEmpty(data)) &&
                    <>
                        <Alert variant="info">
                            Esta vista <b>NO</b> incluye el trafico de Google Ads ni el trafico organico
                        </Alert>
                        <Row className="justify-content-md-center">
                            <h2><Badge variant="info" pill>Summary</Badge></h2>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Revenue</Card.Header>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.revenue) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Cost</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatPrice(data.summary.cost) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Profit (Result)</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatPrice(data.summary.profit) } </Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Sales Conversions</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalSalesConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Conversions SOI</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalSOIConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Adquisición</Tooltip>}>
                                        <Card.Header className="p-2">CPA</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.cpa) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste / Primeras Compras</Tooltip>}>
                                        <Card.Header className="p-2">CPA 1</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.cpa1) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">CPL</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.cpl) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">PAQ_100</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPAQ_100Conv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">PAQ_369</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPAQ_369Conv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">PAQ_709</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPAQ_709Conv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">PAQ_1034</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPAQ_1034Conv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">PAQ_1723</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPAQ_1723Conv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">PAQ_80</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPAQ_80Conv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">DB</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalDBConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">SMS</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalSMSConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Visa</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalVisaConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Phone</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalPhoneConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">First Purchases</Card.Header>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalFirst) }</Card.Title>
                                        <Card.Text className="text-center mb-0">{ formatPrice(data.summary.amountFirst) }</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Rebilling</Card.Header>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalRebilling) }</Card.Title>
                                        <Card.Text className="text-center mb-0">{ formatPrice(data.summary.amountRebilling) }</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Table data={data.results} />
                        <Pagination
                            handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                            pager={data.pager}
                            total={data.total}
                            paginate={this.paginate}
                            prevPage={this.prevPage}
                            nextPage={this.nextPage}
                        />
                    </>
                }
                { (isEmpty(data)) &&
                    <Container>
                        <Row className="justify-content-md-center">
                            <h2><Badge variant="warning" pill> <FontAwesomeIcon icon={faBan} className="mr-2" />Ups, no hay datos. Inténtelo con otros parámetros de búsqueda</Badge></h2>
                        </Row>
                    </Container>
                }
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(Campaigns))
