import React, { Component } from "react";
import { Link } from "react-router-dom";
//style
import "./index.scss";

class Footer extends Component {
  render() {
    return (
        <footer className="page-footer font-small blue py-4 mt-auto">
            <div class="footer-copyright text-center py-3 small">
              {'Copyright © '}
              <Link color="inherit" href="https://promocionesweb.com/">
                  PromocionesWeb 2016, S.L.
              </Link>{' '}
              {new Date().getFullYear()}
              {'. '}
              |
              <Link color="inherit" href="https://legal.promocionesweb.com/condiciones.php?id_pagina=17">
                  Legal
              </Link>
              |
              <Link color="inherit" href="https://legal.promocionesweb.com/condiciones.php?id_pagina=18">
                  Política de Privacidad
              </Link>
              |
              <Link color="inherit" href="https://legal.promocionesweb.com/condiciones.php?id_pagina=19">
                  Cookies
              </Link>
          </div>
        </footer>
    );
  }
}
export default Footer;
