import React from 'react'
import { withRouter } from 'react-router-dom';
import es from 'date-fns/locale/es';
import DatePicker, {registerLocale} from 'react-datepicker'
import { getLang } from '../../libs/cookies';
import { click } from '../../libs/methods';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import {Col, Card, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const ConversionForm = (props) => {
    registerLocale('es', es)
    let format_start_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.start_date);
    let format_end_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.end_date);
    return (

        <Form className="home-form">
            <Form.Row className="align-items-center">

                {/*<Col xs="auto">
                    <div className="form-group calendar sp">
                        <Form.Label htmlFor="inlineFormInputName2">Fecha</Form.Label>
                        <div className="form-control">
                            <DatePicker
                                selected={props.start_date}
                                locale={getLang()}
                                dateFormat="dd/MM/yyyy"
                                onChange={ date => props.handleChangeDate(date) }
                                id="calendar"
                                popperPlacement="top"
                            />
                        </div>
                        <FontAwesomeIcon
                            className="icon-calendar-conversions" icon={faCalendar}
                            style={{ color: '#333' }} onClick={e => click("calendar")}
                        />
                    </div>
                </Col>*/}
                <Col xs="auto">
                    <Form.Group><Form.Control className="handed" onClick={e => click("calendar_date")} as="text">{ format_start_date } { props.end_date && " al " + format_end_date }</Form.Control></Form.Group>
                </Col>
                {<Col xs="auto">
                    <div className="form-group calendar sp">
                        <Form.Label htmlFor="inlineFormInputName2" srOnly>Fecha</Form.Label>
                        <div className="form-control mb-2 datepickercustom">
                            <DatePicker
                                selected={props.start_date}
                                startDate={props.start_date}
                                endDate={props.end_date}
                                maxDate={props.yesterday}
                                selectsRange
                                onChange={dates => props.handleChangeDate(dates)}
                                locale={getLang()}
                                dateFormat="dd/MM/yyyy"
                                monthsShown={2}
                                id="calendar_date"
                                placeholder="Seleccione Fecha"
                                popperPlacement="top"
                            />
                        </div>
                        <FontAwesomeIcon
                            className="icon-calendar" icon={faCalendar}
                            style={{ color: '#333' }} onClick={e => click("calendar_date")}
                        />
                    </div>
                </Col>}

                <Col xs="auto">
                    <Form.Group>
                        <Form.Control
                            as="select"
                            custom
                            name="range_date"
                            placeholder="Range Date"
                            onChange={props.handleChangeRang}
                        >
                            <option value="">Select Date Range</option>
                            <option value="1">Yesterday</option>
                            <option value="2">Last 48h</option>
                            <option value="3">Last 7 days</option>
                            <option value="4">Last 30 days</option>
                            <option value="5">This Month</option>
                            <option value="6">Last Month</option>
                            <option value="7">This Year</option>
                            <option value="8">Last Year</option>
                        </Form.Control>
                    </Form.Group>
                </Col>

                {/*<Col xs="auto">
                    <Form.Group className="">
                        <Form.Label htmlFor="inlineFormInputName2">Traffic Source</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            name="traffic_sources"
                            onChange={props.handleOnTFChange.bind(this)}
                            placeholder="Traffic Source"
                        >
                            <option value="">Todas</option>
                            { Array.isArray(props.trafficSourcesData) &&
                                <React.Fragment>
                                {
                                    props.trafficSourcesData.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                                </React.Fragment>
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>


                    props.selectCampaign &&
                    <Col xs="auto">
                        <Form.Group className="">
                            <Form.Label htmlFor="inlineFormInputName2">Campaigns</Form.Label>
                            <Form.Control
                                as="select"
                                custom
                                className={`form-control ${props.error ? "is-invalid" : ""}`}
                                name="campaigns"
                                aria-describedby="emailHelp"
                                onChange={props.handleOnChange}
                                placeholder="Campaign"
                            >
                                <option value="">Todas</option>
                                { Array.isArray(props.campaignData) &&
                                    <React.Fragment>
                                    {
                                        props.campaignData.map((item, index) => {
                                            return (
                                                <option value={item.index}>{item.name}</option>
                                            )
                                        })
                                    }
                                    </React.Fragment>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                <Col xs="auto">
                    <Form.Group className="">
                        <Form.Label htmlFor="inlineFormInputName2">Type Conversion</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            className={`form-control ${props.error ? "is-invalid" : ""}`}
                            name="type_conversion"
                            aria-describedby="emailHelp"
                            onChange={props.handleOnChange}
                            placeholder="Type Conversion"
                        >
                            <option value="">Todas</option>
                            { Array.isArray(props.typeConversionsData) &&
                                <React.Fragment>
                                {
                                    props.typeConversionsData.map((item, index) => {
                                        return (
                                            <option value={item.index}>{item.name}</option>
                                        )
                                    })
                                }
                                </React.Fragment>
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Col xs="auto">
                    <Form.Group>
                        <Form.Label for="inputEmailAddress">Buscar</Form.Label>
                        <Form.Control
                            type="text"
                            className={`form-control ${props.error ? "is-invalid" : ""}`}
                            name="search"
                            placeholder="Buscar"
                            onChange={props.handleOnChange}
                        />
                    </Form.Group>
                </Col>*/}

                <Col xs="auto">
                    <Form.Group>
                        <Button type="button" className="form-control" onClick={e => props.getStats()}>Enviar</Button>
                    </Form.Group>
                </Col>
            </Form.Row>
        </Form>

    )
}

export default withRouter(ConversionForm)
