import React, { Component } from "react";
import CustomConversionsService from "../../services/custom-conversions-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Pagination from '../../components/pagination'
import Form from './form'
import Table from './table'


class CustomConversions extends Component {
    constructor(props) {
        super(props);
        //var tempDate = new Date();
        var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
        //let prevStartDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDay());
        this.state = {
            filters: {
                search: '',
                pager: {
                    page: '1',
                    items: '999'
                }
            },
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info', // error, warning, info, success
            date: yesterday,
            data: null,
        };
    }
    componentDidMount() {
        this.getStats();
    }

    getStats = (e) => {
        this.setState({
            data: null,
        });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CustomConversionsService.getCustomConversions( getAuth().token, this.state.filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({
                    data: response.data.message,
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        }).catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        }).then(response => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        })
        //this.logger = new RichLogger("Login");
    }
    handleOnChange = (e) => {
        this.setState({
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info'
        })
    }

    handleChangeDate = (date) => {
        console.log(this.state.filters.pager);
        this.setState({
            filters: {
                start_date: date
            },
            date: date
        })
    }

    paginate = (e) => {
        const { filters } = this.state
        filters.pager.page = e.target.value
        this.getStats()
    }

    handleChangeNumberOfColum = (e) => {
        const { filters } = this.state
        filters.pager.items = e.target.value
        filters.pager.page = 1
        this.getStats()
    }

    nextPage = (page) => {
        const { filters } = this.state
        page++
        if(page <= this.state.data.pager.pages) {
            filters.pager.page = page
            this.getStats()
        }
    }

    prevPage = (page) => {
        const { filters } = this.state
        page--
        if(page > 0) {
            filters.pager.page = page
            this.getStats()
        }
    }

    render() {
        return (
            <Layout>
                <Breadcrumb>
                    <NavLink exact={true} activeClassName='active' to="/conversions" role="button">
                        <FontAwesomeIcon icon={faCommentDollar} className="mr-2" />
                        Custom Conversions
                    </NavLink>
                </Breadcrumb>
                <Form
                    handleOnChange={this.handleOnChange}
                    handleChangeDate={this.handleChangeDate}
                    date={this.state.date}
                    getStats={this.getStats}
                />
                <hr />
                { (this.props.loading) &&
                    <Loading />
                }
                {
                    (!isEmpty(this.state.data)) &&
                    <React.Fragment>
                        <Table data={this.state.data.results} />
                        <Pagination
                            handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                            pager={this.state.data.pager}
                            total={this.state.data.total}
                            paginate={this.paginate}
                            prevPage={this.prevPage}
                            nextPage={this.nextPage}
                        />
                    </React.Fragment>
                }
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(CustomConversions))
