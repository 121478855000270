const initialState = {
    auth: {},
    logged: false
  };

  // auth reducer
  /* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = initialState, action) => {
    switch (action.type) {
      case "SET_AUTH":
        return Object.assign({}, state, {
          auth: action.auth.auth,
          logged: action.auth.logged
        });
      default:
        return state;

    }
  };
