import request from "./api-service";

function login(user, pwd) {
  return request({
    url: `/login`,
    method: "POST",
    data: { user: user, pwd: pwd },
  });
}

function get(token){
    return request({
      url: `/users/get`,
      method: "POST",
      data: { token: token },
    });
}

function update(token, data){
    return request({
      url: `/users/update`,
      method: "POST",
      data: { token: token, data: data },
    });
}

const UserService = {
  login,
  get,
  update
};

export default UserService;
