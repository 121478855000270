import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import { getLang } from "../../libs/cookies";
import { click } from "../../libs/methods";

import Chips from "react-chips";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Col, Form, Button } from "react-bootstrap";

//style
import "./index.scss";

const LteForm = (props) => {
  registerLocale("es", es);
  let format_start_date = new Intl.DateTimeFormat("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(props.start_date);
  let format_end_date = new Intl.DateTimeFormat("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(props.end_date);

  return (
    <Form className="home-form">
      <Form.Row className="align-items-center">
        <Col xs="auto">
          <Form.Group>
            <Form.Control
              className="handed"
              onClick={(e) => click("calendar_date")}
              as="text"
            >
              {format_start_date} {props.end_date && " al " + format_end_date}
            </Form.Control>
          </Form.Group>
        </Col>
        {
          <Col xs="auto">
            <div className="form-group calendar sp">
              <Form.Label htmlFor="inlineFormInputName2" srOnly>
                Fecha
              </Form.Label>
              <div className="form-control mb-2 datepickercustom">
                <DatePicker
                  selected={props.start_date}
                  startDate={props.start_date}
                  endDate={props.end_date}
                  maxDate={props.yesterday}
                  selectsRange
                  onChange={(dates) => props.handleChangeDate(dates)}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  monthsShown={2}
                  id="calendar_date"
                  placeholder="Seleccione Fecha"
                  popperPlacement="top"
                />
              </div>
              <FontAwesomeIcon
                className="icon-calendar"
                icon={faCalendar}
                style={{ color: "#333" }}
                onClick={(e) => click("calendar_date")}
              />
            </div>
          </Col>
        }

        <Col xs="auto">
          <Form.Group>
            <Form.Control
              as="select"
              custom
              name="range_date"
              placeholder="Range Date"
              onChange={props.handleChangeRang}
            >
              <option value="">Select Date Range</option>
              <option value="1">Yesterday</option>
              <option value="2">Last 48h</option>
              <option value="3">Last 7 days</option>
              <option value="4">Last 30 days</option>
              <option value="5">This Month</option>
              <option value="6">Last Month</option>
              <option value="7">This Year</option>
              <option value="8">Last Year</option>
              <option value="9">Always</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xs="auto">
          <Form.Group>
            <Form.Control
              as="select"
              custom
              name="campaign_type"
              placeholder="Campaign Type"
              onChange={props.handleOnChange}
              value={props.campaign_type}
            >
              <option value="">All Campaigns</option>
              <option value="0">Organic</option>
              <option value="1">Voluum</option>
              <option value="2">Adwords</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xs="5">
          <Form.Group>
            {/* <Form.Control
              as="select"
              custom
              name="campaign_id"
              placeholder="Campaign Id"
              onChange={props.handleOnChange}
            >
              <option value="">Todas</option>
              {Array.isArray(props.data_campaigns) && (
                <>
                  {props.data_campaigns.map((item, index) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </>
              )}
            </Form.Control> */}
            <CampaignChips
              suggestionChips={props.data_campaigns}
              handleOnChange={props.handleOnChange}
            />
          </Form.Group>
        </Col>

        <Col xs="auto">
          <Form.Group>
            <Button
              type="button"
              className="form-control"
              onClick={(e) => props.getStats()}
            >
              Enviar
            </Button>
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default withRouter(LteForm);

const CampaignChips = ({ suggestionChips, handleOnChange }) => {
  const [chips, setChips] = useState([]);

  const onChangeChips = (chips) => {
    setChips([chips[chips.length - 1]]);
    if (chips[chips.length - 1]) {
      handleOnChange({
        target: {
          value: chips[chips.length - 1].id,
          name: "campaign_id",
        },
      });
    } else setChips([]);
  };

  const fetchSuggestions = async (searchValue) => {
    return new Promise((resolve, reject) => {
      if (searchValue.length >= 1) {
        let results = [];
        suggestionChips.forEach((element) => {
          if (element.name.toLowerCase().includes(searchValue.toLowerCase()))
            results.push(element);
        });
        resolve(results);
      } else resolve([]);
    });
  };
  return (
    <Chips
      value={chips}
      onChange={onChangeChips}
      uniqueChips
      placeholder="Escribe para buscar la campaña"
      fromSuggestionsOnly
      fetchSuggestions={fetchSuggestions}
      getSuggestionValue={(suggestion) => suggestion.value}
      renderSuggestion={(item) => <div key={item.ID}>{item.name}</div>}
      renderChip={(item) => (
        <div className="campaign_chips" key={item.ID}>
          {item.name}
        </div>
      )}
    />
  );
};
