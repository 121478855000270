import { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

//Bootstrap
import { Button, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

// Methods
import { useSortableData, formatPrice } from '../../libs/methods';

import CostModal from './costModalAdwords'

//style
import "./index.scss";

class TableAdwords extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showCostModal: false,
            costItem: null,
            data: props.data,
            sortDirection: 'asc'
        };
        this.onSort = this.onSort.bind(this)
    }

    onSort(event, sortKey){
        const data = this.state.data;
        /*data.sort((a, b) => {
            if (a[sortKey] < b[sortKey]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortKey] > b[sortKey]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }

            return 0;
        });*/

        data.sort((a, b) => a[sortKey] > b[sortKey] ? -1 : 1);
        this.setState({
            data: data
        })
    }

    handleShowCostModal = (item) => {
        this.setState({
            showCostModal: true,
            costItem: item
        });
    }

    handleClose = () => {
        this.setState({
            showCostModal: false,
            costItem: null
        });
        this.props.getStats();
    }

    render() {

        const { costItem, showCostModal } = this.state
        var newdata = this.state.data;
        /*const { items, requestSort, sortConfig } = useSortableData(this.props.data);
        const getClassNamesFor = (name) => {
            if (!sortConfig) {
                return;
            }
            return sortConfig.key === name ? sortConfig.direction : undefined;
        };*/
    return (
        <>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr className="text-center">
                        <th>Date</th>
                        <th>Campaign Name</th>
                        <th className="sortable"><span onClick={e => this.onSort(e, 'cost')}>Cost</span></th>
                        <th>Edit Cost</th>
                    </tr>
                </thead>
                <tbody>
                    { //items.length > 0 &&
                        newdata.length > 0 &&
                        <>
                            { newdata.map((item, index) => {
                                return (
                                    <tr key={index} className={((index+1)%2)?"impar":"par"} data-item={item}>
                                        <td><small>{ item.date }</small></td>
                                        <td><small>{ item.campaignName }</small></td>
                                        <td data-title="cost"><small>{ formatPrice(item.cost) }</small></td>
                                        <td className="text-center"><small><Button variant="secondary" block onClick={(e) => { this.handleShowCostModal(item)} }><FontAwesomeIcon icon={faEdit} className="mr-2" /></Button></small></td>
                                    </tr>
                                )
                            }) }
                        </>
                    }
                </tbody>
            </Table>
            { showCostModal && <CostModal show={true} item={costItem} handleClose={this.handleClose} handleSaveCost={this.handleSaveCost} />}
      </>
    )
}
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(TableAdwords))
