import request from "./api-service";

function getData(token, filters) {
    return request({
        url: `/firstpurchases/list`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}


const FirstPurchasesService = {
    getData
};

export default FirstPurchasesService;
