import request from "./api-service";

function getCosts(token, filters) {
    return request({
        url: `/costs/list`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}

function updateCosts(token, data, filters) {
    return request({
        url: `/costs/update`,
        method: "POST",
        data: {
            token: token,
            data: data,
            filters: filters
        },
    });
}

function updateCostsAdwords(token, data, filters) {
    return request({
        url: `/costs/updateAdwords`,
        method: "POST",
        data: {
            token: token,
            data: data,
            filters: filters
        },
    });
}

function getCostsAdwords(token, filters) {
    return request({
        url: `/costs/adwords`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}

const CostsService = {
    getCosts,
    updateCosts,
    getCostsAdwords,
    updateCostsAdwords
};

export default CostsService;
