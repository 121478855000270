import request from "./api-service";

function getConversions(token, filters) {
    return request({
        url: `/conversions/list`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}

function getCampaignConversions(token, filters) {
    return request({
        url: `/conversions/campaignlist`,
        method: 'POST',
        data: {
            token: token,
            filters: filters
        }
    });
}

function getCampaignDetailConversions(token, filters) {
    return request({
        url: `/conversions/campaigndetail`,
        method: 'POST',
        data: {
            token: token,
            filters: filters
        }
    });
}

const ConversionsService = {
    getConversions,
    getCampaignConversions,
    getCampaignDetailConversions
};

export default ConversionsService;
