import React from 'react'
import { withRouter } from 'react-router-dom';
import es from 'date-fns/locale/es';
import DatePicker, {registerLocale} from 'react-datepicker'
import { getLang } from '../../libs/cookies';
import { click } from '../../libs/methods';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import {Card, Col, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const ConversionForm = (props) => {
    registerLocale('es', es)
    let format_start_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.start_date);
    let format_end_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.end_date);
    return (

        <Form className="home-form">
            <Form.Row className="align-items-center">
                {/*
                <Col xs="auto">
                    <div className="form-group calendar sp">
                        <Form.Label htmlFor="inlineFormInputName2">Fecha</Form.Label>
                        <div className="form-control">
                            <DatePicker
                                selected={props.date}
                                onChange={date => props.handleChangeDate(date)}
                                locale={getLang()}
                                dateFormat="yyyy-MM"
                                id="calendar"
                                showMonthYearPicker
                                popperPlacement="top"
                            />
                        </div>
                    </div>
                </Col>
                */}
                <Col xs="auto">
                    <Card>
                        <Card.Body>
                            <Card.Title><FontAwesomeIcon className="icon-calendar" icon={faCalendar} /> Periodo</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{ format_start_date } { props.end_date && " al " + format_end_date }</Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="auto">
                    <div className="form-group calendar sp">
                        <Form.Label htmlFor="inlineFormInputName2">Fecha</Form.Label>
                        <div className="form-control">
                            <DatePicker
                                selected={props.start_date}
                                startDate={props.start_date}
                                endDate={props.end_date}
                                maxDate={props.yesterday}
                                selectsRange
                                onChange={dates => props.handleChangeDate(dates)}
                                locale={getLang()}
                                dateFormat="dd/MM/yyyy"
                                monthsShown={2}
                                id="calendar_date"
                                popperPlacement="top"
                            />
                        </div>
                        <FontAwesomeIcon
                            className="icon-calendar-conversions" icon={faCalendar}
                            style={{ color: '#333' }} onClick={e => click("calendar_date")}
                        />
                    </div>
                </Col>
                <Col xs="auto">
                    <Form.Group className="">
                        <Form.Label htmlFor="inlineFormInputName2">Traffic Source</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            name="traffic_sources"
                            onChange={props.handleOnTFChange.bind(this)}
                            placeholder="Traffic Source"
                        >
                            <option value="">Todas</option>
                            { Array.isArray(props.trafficSourcesData) &&
                                <React.Fragment>
                                {
                                    props.trafficSourcesData.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                                </React.Fragment>
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
                {
                    props.selectCampaign &&
                    <Col xs="auto">
                        <Form.Group className="">
                            <Form.Label htmlFor="inlineFormInputName2">Campaigns</Form.Label>
                            <Form.Control
                                as="select"
                                custom
                                className={`form-control ${props.error ? "is-invalid" : ""}`}
                                name="campaigns"
                                aria-describedby="emailHelp"
                                onChange={props.handleOnChange}
                                placeholder="Campaign"
                            >
                                <option value="">Todas</option>
                                { Array.isArray(props.campaignData) &&
                                    <React.Fragment>
                                    {
                                        props.campaignData.map((item, index) => {
                                            return (
                                                <option value={item.index}>{item.name}</option>
                                            )
                                        })
                                    }
                                    </React.Fragment>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }
                <Col xs="auto">
                    <Form.Group className="">
                        <Form.Label htmlFor="inlineFormInputName2">Type Conversion</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            className={`form-control ${props.error ? "is-invalid" : ""}`}
                            name="type_conversion"
                            aria-describedby="emailHelp"
                            onChange={props.handleOnChange}
                            placeholder="Type Conversion"
                        >
                            <option value="">Todas</option>
                            { Array.isArray(props.typeConversionsData) &&
                                <React.Fragment>
                                {
                                    props.typeConversionsData.map((item, index) => {
                                        return (
                                            <option value={item.index}>{item.name}</option>
                                        )
                                    })
                                }
                                </React.Fragment>
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group>
                        <Form.Label for="inputEmailAddress">Buscar</Form.Label>
                        <Form.Control
                            type="text"
                            className={`form-control ${props.error ? "is-invalid" : ""}`}
                            name="search"
                            placeholder="Buscar"
                            onChange={props.handleOnChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <Button type="button" className="form-control" onClick={e => props.getStats()}>Enviar</Button>
                    </Form.Group>
                </Col>
            </Form.Row>
        </Form>

    )
}

export default withRouter(ConversionForm)
