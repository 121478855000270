import React, {useState}  from "react";
import { Accordion, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faChartLine, faFunnelDollar, faPhoneVolume} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import classNames from "classnames";

const SubMenuLTE = ({ icon, title }) => { 
  
  const [collapsed, setCollapsed] = useState(true); 
  const toggleNavbar = () => setCollapsed( collapsed => !collapsed );

  return (
    <Nav.Item className={classNames({ open: !collapsed })}>
      <Accordion>
        <Accordion.Toggle
          as={Nav.Link}
          variant="link"
          eventKey="0"
          onClick={toggleNavbar}
        >
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {title}
          <FontAwesomeIcon
            icon={collapsed ? faCaretDown : faCaretUp}
            className="float-right"
          />
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <Nav className="flex-column">
              <Nav.Item className="nav-link">
                  <NavLink exact={true} activeClassName='active' to="/lte" role="button">
                      <FontAwesomeIcon icon={faChartLine} className="mr-2" />
                      LTE (Stats)
                  </NavLink>
              </Nav.Item>

              <Nav.Item className="nav-link">
                  <NavLink exact={true} activeClassName='active' to="/lteCampaigns" role="button">
                      <FontAwesomeIcon icon={faFunnelDollar} className="mr-2" />
                      LTE (Campaigns)
                  </NavLink>
              </Nav.Item>
              
              <Nav.Item className="nav-link">
                  <NavLink exact={true} activeClassName='active' to="/lte803" role="button">
                      <FontAwesomeIcon icon={faPhoneVolume} className="mr-2" />
                      803 (Stats)
                  </NavLink>
              </Nav.Item>

          </Nav>
        </Accordion.Collapse>
      </Accordion>
    </Nav.Item>
  ); 
} 

export default SubMenuLTE;
