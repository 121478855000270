import request from "./api-service";

function getList(token, filters) {
  return request({
    url: `/offers/list`,
    method: "POST",
    data: {
        token: token,
        filters: filters
    },
  });
}

const OffersService = {
  getList
};

export default OffersService;
