import React, { Component } from "react";
//style
import "./index.scss";
import { ReactComponent as VoluumLogoIcon } from '../../assets/img/pw_voluum_logo.svg';
import Footer from "../footer";

class Layout extends Component {
    constructor (props) {
        super(props)
        this.state = {
            headerHeight: 0,
            footerHeight: 0,
            showMenu: true,
            showSidebar: true,
            showUserMenu: true,
        }
    }
  render() {
    return (
        <div className="loginLayout-container">
            <header className="text-center">  <VoluumLogoIcon /> </header>
            <main>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />

        </div>
    );
  }
}
export default Layout;
