import React from 'react';
import { withRouter } from 'react-router-dom';

//Bootstrap
import { Table, Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Methods
import { formatPrice, formatNumber } from '../../libs/methods';

//style
import "./index.scss";

const ConversionsTable = (props) => {
    const { month, year, days } = props.data;
    let balanceClass = 'table-success';
    return (
        <>
            <Row className="align-items-center">
                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Year</Badge></h2>
                { (year) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Year</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}><th>Revenue</th></OverlayTrigger>
                                <th>Customers</th>
                                <th>Orders</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}><th>AOF</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}><th>*AOS</th></OverlayTrigger>                                
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}><th>ACV</th></OverlayTrigger>
                            </tr>
                        </thead>
                        <tbody>
                        { Object.keys(year).map((key, value ) => {
                            balanceClass = '';

                            return (

                                <tr className={balanceClass}>
                                    <td>{key}</td>
                                    <td>{ formatPrice(year[key].totalSpent) }</td>
                                    <td>{ formatNumber(year[key].totalCustomers) }</td>
                                    <td>{ formatNumber(year[key].totalOrders) }</td>
                                    <td>{ formatNumber(year[key].AOF) }</td>
                                    <td>{ formatPrice(year[key].AOS) }</td>                                    
                                    <td>{ formatPrice(year[key].ACV) }</td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                }

                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Month</Badge></h2>
                { (month) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}><th>Revenue</th></OverlayTrigger>
                              
                                <th>Customers</th>
                                <th>Orders</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}><th>AOF</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}><th>*AOS</th></OverlayTrigger>                                
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}><th>ACV</th></OverlayTrigger>

                            </tr>
                        </thead>
                        <tbody>
                        { Object.keys(month).map((key1, value ) => {
                                balanceClass = '';


                            return (
                                <tr className={balanceClass}>
                                    <td>{key1}</td>
                                    <td>{ formatPrice(month[key1].totalSpent) }</td>
                                    <td>{ formatNumber(month[key1].totalCustomers) }</td>
                                    <td>{ formatNumber(month[key1].totalOrders) }</td>
                                    <td>{ formatNumber(month[key1].AOF) }</td>
                                    <td>{ formatPrice(month[key1].AOS) }</td>                                    
                                    <td>{ formatPrice(month[key1].ACV) }</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                }

                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Day</Badge></h2>
                { (days) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Day</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}><th>Revenue</th></OverlayTrigger>
                                <th>Customers</th>
                                <th>Orders</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}><th>AOF</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}><th>*AOS</th></OverlayTrigger>                               
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}><th>ACV</th></OverlayTrigger>
                            </tr>
                        </thead>
                        <tbody>
                            { Object.keys(days).map((key2, value ) => {
                                    balanceClass = '';

                                return (
                                    <tr className={balanceClass}>
                                        <td>{key2}</td>
                                        <td>{ formatPrice(days[key2].totalSpent) }</td>
                                        <td>{ formatNumber(days[key2].totalCustomers) }</td>
                                        <td>{ formatNumber(days[key2].totalOrders) }</td>
                                        <td>{ formatNumber(days[key2].AOF) }</td>
                                        <td>{ formatPrice(days[key2].AOS) }</td>                                        
                                        <td>{ formatPrice(days[key2].ACV) }</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </Row>
        </>
    )
}

export default withRouter(ConversionsTable)
