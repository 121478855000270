import React from 'react'
import { withRouter } from 'react-router-dom';

//Bootstrap
import {Table } from 'react-bootstrap';

//style
import "./index.scss";

const TSTable = (props) => {
    return (

        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th>ID</th>
                    <th>Name</th>
                    <th>TYPE</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.data.length > 0 &&
                        <React.Fragment>
                            {
                                props.data.map((item, index) => {
                                    return (
                                        <tr key={index} className={((index+1)%2)?"impar":"par"}>
                                            <td><small>{item.id}</small></td>
                                            <td><small>{item.name}</small></td>
                                            <td><small>{item.type}</small></td>
                                        </tr>
                                    )
                                })
                            }
                        </React.Fragment>
                }
            </tbody>
        </Table>

    )
}

export default withRouter(TSTable)
