import React from 'react';
import { withRouter, Link } from 'react-router-dom';

//Bootstrap
import { Table } from 'react-bootstrap';

// Methods
import { useSortableData } from '../../libs/methods';

//style
import "./index.scss";

const ConversionsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th className="sortable"><span onClick={() => requestSort('campaignName')}
                        className={getClassNamesFor('campaignName')}>Campaign Name</span></th>
                    <th className="sortable"><span onClick={() => requestSort('offerName')}
                        className={getClassNamesFor('offerName')}>Offer Name</span></th>
                    <th className="sortable"><span onClick={() => requestSort('revenue')}
                        className={getClassNamesFor('revenue')}>Revenue</span></th>
                    <th className="sortable"><span onClick={() => requestSort('cost')}
                        className={getClassNamesFor('cost')}>Cost</span></th>
                    <th className="sortable"><span onClick={() => requestSort('profit')}
                        className={getClassNamesFor('profit')}>Profit</span></th>
                    <th className="sortable"><span onClick={() => requestSort('conversions')}
                        className={getClassNamesFor('conversions')}>Conversions</span></th>
                    <th>CPA</th>
                    <th>LEAD</th>
                    <th>CPL</th>
                    <th>Click ID</th>
                    <th>Device</th>
                    <th>OS</th>
                    <th>Transaction ID</th>
                    <th>Link</th>
                </tr>
            </thead>
            <tbody>
                { items.length > 0 &&
                    <>
                        { items.map((item, index) => {
                            return (
                                <tr key={index} className={((index+1)%2)?"impar":"par"}>
                                    <td><small>{item.campaignName}</small></td>
                                    <td><small>{item.offerName}</small></td>
                                    <td><small>{item.revenue / 100}</small></td>
                                    <td><small>{item.cost}</small></td>
                                    <td><small>{item.profit}</small></td>
                                    <td><small>{item.conversions}</small></td>
                                    <td><small>PEND. CPA</small></td>
                                    <td><small>PEND. LEAD</small></td>
                                    <td><small>PEND. CPL</small></td>
                                    <td><small>{item.clickId}</small></td>
                                    <td><small>{item.device}</small></td>
                                    <td><small>{item.os}</small></td>
                                    <td><small>{item.transactionId}</small></td>
                                    <td><small><Link to={{ pathname: item.campaignUrl }} role="button" target="_blank" rel="noopener noreferrer">LINK</Link></small></td>
                                </tr>
                            )
                        }) }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default withRouter(ConversionsTable)
