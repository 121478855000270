import React, { Component } from "react";
import UserService from "../../services/user-service";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

//libs
import { setAuth, getAuth } from "../../libs/cookies";
import { isEmpty } from '../../libs/methods';

import LoginLayout from '../../components/loginLayout'
import Loading from '../../components/loading'
import LoginForm from './form'


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        user: {
            user: '',
            pwd: '',
        },
        loader: false,
        error: false,
        showAlert: false,
        alertText: '',
        alertSeverity: 'info' // error, warning, info, success
    };
  }

  componentDidMount() {
    //this.logger = new RichLogger("Login");
    if(getAuth()) {
        this.props.history.push("/")
    }
  }
  login = (e) => {
      this.setState({ loader: true });

      e.preventDefault();
      const { user, pwd } = this.state.user

      if (isEmpty(user) || isEmpty(pwd) ){
          this.setState({
              showAlert: true,
              alertText: 'Faltan campos por rellenar',
              error: true,
              alertSeverity: 'warning' // error, warning, info, success
          });

      } else {
          /*userLogin( user,  pwd ).then(res => {
              const auth = res.data.message
              if(res.data.status !== "Failed") {
                 alert('Login OK');
              } else {
                  alert('Login KO');
              }
          })*/


          UserService
              .login( user, pwd )
              .then(response => {
                  const auth = response.data.message
                  if(response.data.status !== "Failed") {
                      //console.log(response);
                      this.props.dispatch({type: 'SET_AUTH', auth: { auth: auth, logged: true }})
                      setAuth(auth);
                      this.props.history.push("/home");
                  } else {
                      this.setState({
                          showAlert: true,
                          alertText: response.data.message,
                          error: true,
                          alertSeverity: 'error' // error, warning, info, success
                      });
                  }
          })
          .catch(error => {
              if (error.message === 'Unauthorized IP, or Origin') {
                  this.setState({
                      showAlert: true,
                      alertText: error.message,
                      error: true,
                      alertSeverity: 'info' // error, warning, info, success
                  });
              }
          })
          .then(res => {
              this.setState({ loader: false });
          });
      }
  }
  handleOnChange = (e) => {
      const { user } = this.state
      const { value, name } = e.target
      this.setState({
          user: {
              ...user,
              [name]: value,
          },
          error: false,
          showAlert: false,
          alertText: '',
          alertSeverity: 'info'
      })
  }
  render() {
    return (
        <React.Fragment>
            {
              (this.state.loader) &&
              <Loading />
            }
            <LoginLayout>

                {this.state.error && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.alertText}
                  </div>
                )}
                <LoginForm
                    login={this.login}
                    handleOnChange={this.handleOnChange}
                    user={this.state.user.user}
                    pwd={this.state.user.pwd}
                    showAlert={this.state.showAlert}
                    alertText={this.state.alertText}
                    alertSeverity={this.state.alertSeverity}
                    error={this.state.error}
                    loader={this.state.loader}
                />

            </LoginLayout>
        </React.Fragment>
    );
  }
}
export default withRouter(connect()(Login))
