import React from 'react'
import { withRouter } from 'react-router-dom';

//translate
import translate from '../../i18n/translate'
import { FormattedMessage } from 'react-intl'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import {Card, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const LoginForm = (props) => {
    return (
        <Card className="shadow-lg border-0 rounded-lg mt-5">
            <Card.Header><h3 class="text-center font-weight-light my-4">{translate('global-title-login')}</h3></Card.Header>
            <Card.Body>
                <Form className="login-form">
                    <Form.Group>
                        <FormattedMessage id="global-email">
                            {(msg) =>
                                <React.Fragment>
                                    <Form.Label className="small mb-1" for="inputEmailAddress" srOnly>{msg}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        className={`form-control py-4 ${props.error ? "is-invalid" : ""}`}
                                        name="user"
                                        value={props.user}
                                        aria-describedby="emailHelp"
                                        onChange={props.handleOnChange}
                                        placeholder={msg}
                                        required
                                    />
                                </React.Fragment>
                            }
                        </FormattedMessage>

                    </Form.Group>
                    <Form.Group>
                        <FormattedMessage id="global-password">
                            {(msg) =>
                                <React.Fragment>
                                <Form.Label className="small mb-1" for="inputPassword" srOnly>{msg}</Form.Label>
                                <Form.Control
                                    type="password"
                                    className={`form-control py-4 ${props.error ? "is-invalid" : ""}`}
                                    value={props.pwd}
                                    name="pwd"
                                    onChange={props.handleOnChange}
                                    required
                                    placeholder={msg}
                                />
                                </React.Fragment>
                            }
                        </FormattedMessage>

                    </Form.Group>
                    <Form.Group>
                        <Form.Check type="checkbox" id="rememberPasswordCheck" label="Recordar Contraseña" />
                    </Form.Group>
                    <Form.Group>
                        <Button
                            type="button"
                            className="btn btn-primary btn-lg btn-block"
                            onClick={ e => props.login(e) }
                        >{translate('global-send')} <FontAwesomeIcon className="icon-calendar" icon={faUser} />
                        </Button>
                    </Form.Group>
                    {/*
                    <Form.Group className="center">
                        <a class="small" href="#">Forgot Password?</a>
                    </Form.Group>
                    */}
                </Form>
            </Card.Body>
            {/*<Card.Footer className="text-center text-muted"><div class="small"><a href="#">Need an account? Sign up!</a></div></Card.Footer>*/}
        </Card>

    )
}

export default withRouter(LoginForm)
