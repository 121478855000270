import { withRouter } from 'react-router-dom';

//Bootstrap
import { Row, Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// LIBS
import {formatPrice, formatNumber, replacePoint } from '../../libs/methods'

//style
import "./index.scss";

const HomeInfo = (props) => {
    const { month, year, days } = props.data;
    return (
        <>
            <Row className="align-items-center">
                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Year</Badge></h2>
                { (year) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Revenue</th>
                                <th>Cost</th>
                                <th>Profit</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}><th>CPA</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Lead</Tooltip>}><th>CPL</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Adquisición Primera Compra</Tooltip>}><th>CPA1</th></OverlayTrigger>
                                <th>Sales Conversions</th>
                                <th>SOI</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / Sales Conversion</Tooltip>}><th>ARC</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / LEAD</Tooltip>}><th>ARL</th></OverlayTrigger>
                                <th>Unique Visits</th>
                                <th>First Purchases</th>
                                <th>First Purchases Amount</th>
                                <th>Rebilling</th>
                                <th>Rebilling Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        { Object.keys(year).map((key, value ) => {
                            return (
                                <tr>
                                    <td>{key}</td>
                                    <td>{ formatPrice(year[key].revenue) }</td>
                                    <td>{ formatPrice(year[key].cost) }</td>
                                    <td>{ formatPrice(year[key].profit) }</td>
                                    <td>{ replacePoint(year[key].cpa) }</td>
                                    <td>{ replacePoint(year[key].cpl) }</td>
                                    <td>{ replacePoint(year[key].cpa1) }</td>
                                    <td>{ formatNumber(year[key].totalSalesConv) }</td>
                                    <td>{ formatNumber(year[key].totalSOIConv) }</td>
                                    <td>{ formatNumber(year[key].arc) }</td>
                                    <td>{ formatNumber(year[key].arl) }</td>
                                    <td>{ formatNumber(year[key].uniqueVisits) }</td>
                                    <td>{ formatNumber(year[key].firstPurchases) }</td>
                                    <td>{ formatPrice(year[key].amountFP) }</td>
                                    <td>{ formatNumber(year[key].rebilling) }</td>
                                    <td>{ formatPrice(year[key].amountRebilling) }</td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                }
                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Month</Badge></h2>
                { (month) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Revenue</th>
                                <th>Cost</th>
                                <th>Profit</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}><th>CPA</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Lead</Tooltip>}><th>CPL</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Adquisición Primera Compra</Tooltip>}><th>CPA1</th></OverlayTrigger>
                                <th>Sales Conversions</th>
                                <th>SOI</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / Sales Conversion</Tooltip>}><th>ARC</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / LEAD</Tooltip>}><th>ARL</th></OverlayTrigger>
                                <th>Unique Visits</th>
                                <th>First Purchases</th>
                                <th>First Purchases Amount</th>
                                <th>Rebilling</th>
                                <th>Rebilling Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        { Object.keys(month).map((key1, value ) => {
                            return (
                                <tr>
                                    <td>{key1}</td>
                                    <td>{ formatPrice(month[key1].revenue) }</td>
                                    <td>{ formatPrice(month[key1].cost) }</td>
                                    <td>{ formatPrice(month[key1].profit) }</td>
                                    <td>{ replacePoint(month[key1].cpa) }</td>
                                    <td>{ replacePoint(month[key1].cpl) }</td>
                                    <td>{ replacePoint(month[key1].cpa1) }</td>
                                    <td>{ formatNumber(month[key1].totalSalesConv) }</td>
                                    <td>{ formatNumber(month[key1].totalSOIConv) }</td>
                                    <td>{ formatNumber(month[key1].arc) }</td>
                                    <td>{ formatNumber(month[key1].arl) }</td>
                                    <td>{ formatNumber(month[key1].uniqueVisits) }</td>
                                    <td>{ formatNumber(month[key1].firstPurchases) }</td>
                                    <td>{ formatPrice(month[key1].amountFP) }</td>
                                    <td>{ formatNumber(month[key1].rebilling) }</td>
                                    <td>{ formatPrice(month[key1].amountRebilling) }</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                }


                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Day</Badge></h2>
                { (days) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Revenue</th>
                                <th>Cost</th>
                                <th>Profit</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}><th>CPA</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Lead</Tooltip>}><th>CPL</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Adquisición Primera Compra</Tooltip>}><th>CPA1</th></OverlayTrigger>
                                <th>Sales Conversions</th>
                                <th>SOI</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / Sales Conversion</Tooltip>}><th>ARC</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / LEAD</Tooltip>}><th>ARL</th></OverlayTrigger>
                                <th>Unique Visits</th>
                                <th>First Purchases</th>
                                <th>First Purchases Amount</th>
                                <th>Rebilling</th>
                                <th>Rebilling Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            { Object.keys(days).map((key2, value ) => {
                                return (
                                    <tr>
                                        <td>{days[key2].date}</td>
                                        <td>{ formatPrice(days[key2].revenue) }</td>
                                        <td>{ formatPrice(days[key2].cost) }</td>
                                        <td>{ formatPrice(days[key2].profit) }</td>
                                        <td>{ replacePoint(days[key2].cpa) }</td>
                                        <td>{ replacePoint(days[key2].cpl) }</td>
                                        <td>{ replacePoint(days[key2].cpa1) }</td>
                                        <td>{ formatNumber(days[key2].salesConv) }</td>
                                        <td>{ formatNumber(days[key2].SOIConv) }</td>
                                        <td>{ formatNumber(days[key2].arc) }</td>
                                        <td>{ formatNumber(days[key2].arl) }</td>
                                        <td>{ formatNumber(days[key2].uniqueVisits) }</td>
                                        <td>{ formatNumber(days[key2].first_purchases) }</td>
                                        <td>{ formatPrice(days[key2].first_purchases_amount) }</td>
                                        <td>{ formatNumber(days[key2].rebilling) }</td>
                                        <td>{ formatPrice(days[key2].rebilling_amount) }</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </Row>
        </>
    )
}

export default withRouter(HomeInfo)
