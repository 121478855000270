import React, { Component } from "react";
import UserProfileService from "../../services/user_profile-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty, formatPrice, formatNumber } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Row, Badge, Alert, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd, faBan } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Pagination from '../../components/pagination'
import Table from './table'
import Form from './form'


class Lte extends Component {
    constructor(props) {
        super(props);
        //var tempDate = new Date(new Date().setDate(new Date().getDate()-365));
        let startYear = new Date(new Date().getFullYear(), 0, 1, 1, 0, 0, 0);
        var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
        //let prevStartDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDay());
        this.state = {
            filters: {
                start_date: startYear,
                end_date: yesterday,
                campaign_type: null,
                campaign_organic: true,
                campaign_voluum: true,
                campaign_adwords: true,
                pager: {
                    page: '1',
                    items: '9999'
                }
            },
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info', // error, warning, info, success
            data: null,
        };
    }
    componentDidMount() {
        this.getUserProfileList();
    }

    getUserProfileList = (e) => {
        console.log(this.state.filters);
        this.setState({ data: null });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        UserProfileService.getList( getAuth().token, this.state.filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({
                    data: response.data.message,
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        }).catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        }).then(response => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        })
    }

    handleOnChange = (e) => {
        const { value, name, type, checked } = e.target;
        const { filters } = this.state

        this.setState({
            filters: {
                ...filters,
                [name]: type === 'checkbox' ? checked : value                
            }
        });
    }

    handleChangeDate = (dates) => {
        const [start_date, end_date] = dates;
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                start_date: start_date,
                end_date: end_date,
            },
        })
    }

    handleSetStartDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                start_date: date
            },
        })
    }

    handleSetEndDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                end_date: date
            },
        })
    }

    paginate = (e) => {
        const { filters } = this.state
        filters.pager.page = e.target.value
        this.getStats()
    }

    handleChangeNumberOfColum = (e) => {
        const { filters } = this.state
        filters.pager.items = e.target.value
        filters.pager.page = 1
        this.getStats()
    }

    nextPage = (page) => {
        const { filters } = this.state
        page++
        if(page <= this.state.data.pager.pages) {
            filters.pager.page = page
            this.getStats()
        }
    }

    prevPage = (page) => {
        const { filters } = this.state
        page--
        if(page > 0) {
            filters.pager.page = page
            this.getStats()
        }
    }

    handleChangeRang = (e) => {
        const { filters } = this.state
        const { value } = e.target
        switch(value){
            case '1':
                let yesteday = new Date(Date.now() - 86400000);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': yesteday,
                    'end_date': yesteday
                    }
                })
                break
            case '2':
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': new Date(new Date().setDate(new Date().getDate() - 2)),
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    }
                })
                break
            case '3':
                let last7days = new Date(new Date().setDate(new Date().getDate() - 7));
                this.setState({
                    filters: {
                      ...filters,
                      'start_date': last7days,
                      'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    },
                  })
                  break
            case '4':
                let last30days = new Date(new Date().setDate(new Date().getDate() - 30));
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': last30days,
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    },
                })
                break
            case '5':
                let currenMonth = new Date()
                let firstDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth(), 1);
                let lastDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth() + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfMonth,
                    'end_date': lastDayOfMonth
                    }
                })
                break
            case '6':
                let lastMonth = new Date()
                let firstDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1), 1);
                let lastDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1) + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfLastMonth,
                    'end_date': lastDayOfLastMonth
                    },
                })
                break
            case '7':
                let firstMonthOfYear = new Date(new Date().getFullYear(), 0, 1);
                let lastMonthOfYear = new Date(new Date().getFullYear(), 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfYear,
                    'end_date': lastMonthOfYear
                    },
                })
                break
            case '8':
                let firstMonthOfLastYear = new Date(new Date().getFullYear() -1, 0, 1);
                let lastMonthOfLastYear = new Date(new Date().getFullYear() -1, 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfLastYear,
                    'end_date': lastMonthOfLastYear
                    },
                })
                break
            case '9':
            let bigBang =   new Date(2019, 0, 1, 1, 0, 0, 0);
            let yesteday2 = new Date(Date.now() - 86400000);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': bigBang,
                    'end_date': yesteday2
                    },
                })
                break
            default:
        }
    }

    render() {
        const { data, filters } = this.state
        let balanceClass;
        if ((!isEmpty(data))) {
            if (data.summary.balance < 0){
                balanceClass = 'table-danger';
            } else if (data.summary.balance > 0) {
                balanceClass = 'table-success';
            } else {
                balanceClass = '';
            }
        }

        return (
            <Layout>
                <Breadcrumb>
                    <NavLink exact={true} activeClassName='active' to="/lte" role="button">
                        <FontAwesomeIcon icon={faHandHoldingUsd} className="mr-2" />Life Time Earnings
                    </NavLink>
                </Breadcrumb>
                <Form
                    handleOnChange={this.handleOnChange}
                    handleChangeDate={this.handleChangeDate}
                    handleChangeRang={this.handleChangeRang}
                    getStats={this.getUserProfileList}
                    start_date={filters.start_date}
                    end_date={filters.end_date}
                    yesterday={new Date(new Date().setDate(new Date().getDate()-1))}
                    campaign_type={filters.campaign_type}
                    campaign_organic={filters.campaign_organic}
                    campaign_voluum={filters.campaign_voluum}
                    campaign_adwords={filters.campaign_adwords}
                />
                <hr />
                { (this.props.loading) &&
                    <Loading />
                }
                {
                    (!isEmpty(data)) &&
                      <>
                        <Alert variant="info">
                        <b>¡Importante!</b> Life Time Earnings asocia la compra de un usuario al dia de su registro. Esto quiere decir que si un usuario se registra en febrero de 2020 y hace una recarga hoy, el revenue de dicho usuario se almacenará en febrero de 2020.
                        </Alert>
                        <Row className="justify-content-md-center">
                            <h2><Badge variant="info" pill>Summary</Badge></h2>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}>
                                        <Card.Header className="p-2">Total Revenue</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.totalSpent) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Cost</Tooltip>}>
                                        <Card.Header className="p-2">Total Cost</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.totalCost) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Balance</Tooltip>}>
                                        <Card.Header className="p-2">Balance</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className={balanceClass + ' p-2'}>
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.balance) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}>
                                        <Card.Header className="p-2">CPA</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.CPA) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Customers</Tooltip>}>
                                        <Card.Header className="p-2">Total Customers</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalCustomers) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>


                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Orders</Tooltip>}>
                                        <Card.Header className="p-2">Total Orders</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(data.summary.totalOrders) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Order Frequency</Tooltip>}>
                                        <Card.Header className="p-2">AOF</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(data.summary.AOF) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Order Size</Tooltip>}>
                                        <Card.Header className="p-2">*AOS</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.AOS) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}>
                                        <Card.Header className="p-2">ACV</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.ACV) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Lifespan</Tooltip>}>
                                        <Card.Header className="p-2">ALC</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0"> { formatNumber(data.summary.ACL) } days </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
{/* 
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Customer LifeTime Value Gross Margin</Tooltip>}>
                                        <Card.Header className="p-2">CLV Gross Margin</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatPrice(data.summary.CLVGMC) } </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col> */}

                        </Row>

                        {<Table data={ data.data } />}
                        <Pagination
                            handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                            pager={data.pager}
                            total={data.total}
                            paginate={this.paginate}
                            prevPage={this.prevPage}
                            nextPage={this.nextPage}
                        />
                    </>
                }
                { (isEmpty(data)) &&
                    <Container>
                        <Row className="justify-content-md-center">
                            <h2><Badge variant="warning" pill> <FontAwesomeIcon icon={faBan} className="mr-2" />Ups, no hay datos. Inténtelo con otros parámetros de búsqueda</Badge></h2>
                        </Row>
                    </Container>
                }
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(Lte))
