import React from 'react'
import { withRouter } from 'react-router-dom';
import es from 'date-fns/locale/es';
import DatePicker, {registerLocale} from 'react-datepicker'
import { getLang } from '../../libs/cookies';

import { click } from '../../libs/methods';

// Translate
//translate
import translate from '../../i18n/translate'

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Col, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const HomeForm = (props) => {
    let format_start_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.start_date);
    let format_end_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.end_date);
    registerLocale('es', es)
    return (
        <>
            <Form className="home-form">
                <Form.Row className="align-items-center">
                    <Col xs="auto">
                        <Form.Group><Form.Control className="handed" onClick={e => click("calendar_date")} as="text">{ format_start_date } { props.end_date && " al " + format_end_date }</Form.Control></Form.Group>
                    </Col>
                    {<Col xs="auto">
                        <div className="form-group calendar sp">
                            <Form.Label htmlFor="inlineFormInputName2" srOnly>Fecha</Form.Label>
                            <div className="form-control mb-2 datepickercustom">
                                <DatePicker
                                    selected={props.start_date}
                                    startDate={props.start_date}
                                    endDate={props.end_date}
                                    maxDate={props.yesterday}
                                    selectsRange
                                    onChange={dates => props.handleChangeDate(dates)}
                                    locale={getLang()}
                                    dateFormat="dd/MM/yyyy"
                                    monthsShown={2}
                                    id="calendar_date"
                                    placeholder="Seleccione Fecha"
                                    popperPlacement="top"
                                />
                            </div>
                            <FontAwesomeIcon
                                className="icon-calendar" icon={faCalendar}
                                style={{ color: '#333' }} onClick={e => click("calendar_date")}
                            />
                        </div>
                    </Col>}
                    {/*
                        <Col xs="auto">
                            <div className="form-group calendar sp">
                                <Form.Label className="mx-2" srOnly>Fecha Desde</Form.Label>
                                <div className="form-control mb-2">
                                    <DatePicker
                                        selected={props.start_date}
                                        onChange={date => props.handleSetStartDate(date)}
                                        selectsStart
                                        startDate={props.start_date}
                                        endDate={props.end_date}
                                        maxDate={props.yesterday}
                                        dateFormat="yyyy-MM-dd"
                                        locale={getLang()}
                                        id="start_calendar_date"
                                        monthsShown={2}
                                        popperPlacement="top"
                                    />
                                </div>
                                <FontAwesomeIcon
                                    className="icon-calendar" icon={faCalendar}
                                    style={{ color: '#333' }} onClick={e => click("start_calendar_date")}
                                />
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div className="form-group calendar sp">
                                <Form.Label className="mx-2" srOnly>Fecha Hasta</Form.Label>
                                <div className="form-control mb-2">
                                    <DatePicker
                                        selected={props.end_date}
                                        onChange={date => props.handleSetEndDate(date)}
                                        selectsEnd
                                        startDate={props.start_date}
                                        endDate={props.end_date}
                                        minDate={props.start_date}
                                        maxDate={props.yesterday}
                                        dateFormat="yyyy-MM-dd"
                                        locale={getLang()}
                                        id="end_calendar_date"
                                        popperPlacement="top"
                                    />
                                </div>
                                <FontAwesomeIcon
                                    className="icon-calendar" icon={faCalendar}
                                    style={{ color: '#333' }} onClick={e => click("end_calendar_date")}
                                />
                            </div>
                        </Col>
                    */}
                    <Col xs="auto">
                        <Form.Group>
                            <Form.Control
                                as="select"
                                custom
                                name="range_date"
                                placeholder="Range Date"
                                onChange={props.handleChangeRang}
                            >
                                <option value="">Select Date Range</option>
                                <option value="1">Yesterday</option>
                                <option value="2">Last 48h</option>
                                <option value="3">Last 7 days</option>
                                <option value="4">Last 30 days</option>
                                <option value="5">This Month</option>
                                <option value="6">Last Month</option>
                                <option value="7">This Year</option>
                                <option value="8">Last Year</option>
                            </Form.Control>
                        </Form.Group>

                    </Col>
                    <Col xs="auto">
                        <Form.Group>
                            <Button type="button" onClick={e => props.getStats()}>{translate('global-send')}</Button>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
        </>
    )
}

export default withRouter(HomeForm)
