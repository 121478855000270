import cookie from 'react-cookies';

export const setAuth = (auth) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
    cookie.save('auth', auth, {
      path: "/",
      expires: expires
    });
}


export const setDebugCookie = (data) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('debug', data,{
    path: "/",
    expires: expires
  });
}

export const setLang = (lang) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('lang', lang, {
    path: "/",
    expires: expires
  });
}

export const getAuth = () => cookie.load('auth', true) !== undefined ? cookie.load('auth') : '';
export const getDebugCookie = () => cookie.load('debug', true);
export const removeAuth = () => cookie.remove("auth", { path: "/" });
export const getLang = () => cookie.load('lang', true) !== undefined ? cookie.load('lang') : '';
