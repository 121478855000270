import React, { Component, Fragment } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import store from './store'

import { getLang } from './libs/cookies'
import { langFormar } from './libs/methods'

import "./App.scss";

// Language
import messages from './i18n'

// Templates
import Login from "./views/login";
import Home from './views/home'
import Campaigns from './views/campaigns'
import Costs from './views/cost'
import FirstPurchases from './views/firstPurchases'
import Lte from './views/lte'
import LteCampaigns from './views/lteCampaigns'
import Lte803 from './views/lte803'
import Offers from './views/offers'
import Conversions from './views/conversions'
import CampaignsConversions from './views/campaignsConversions'
import CampaignsDetailConversions from './views/campaignsDetailConversions'
import CustomConversions from './views/customConversions'
import TrafficSources from './views/trafficSources'
import UserDetail from './views/userDetail'
import NotFound from './views/notFound'

class App extends Component {
    constructor (props) {
        super(props)
        this.state = {}
        this.lang = langFormar(getLang() ? getLang() : navigator.language)
    }

    render(){
        return (
            <IntlProvider
                locale={this.lang}
                textComponent={Fragment}
                messages={messages[this.lang]}
            >
                <Provider store={store}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/login" component={Login} />
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/raw_campaigns" component={Campaigns} />
                            <Route exact path="/lte" component={Lte} />
                            <Route exact path="/lteCampaigns" component={LteCampaigns} />
                            <Route exact path="/lte803" component={Lte803} />
                            <Route exact path="/offers" component={Offers} />
                            <Route exact path="/campaigns" component={CampaignsConversions} />
                            <Route exact path="/campaigns/detail/:campaignId" component={CampaignsDetailConversions} />
                            <Route exact path="/costs" component={Costs} />
                            <Route exact path="/first-purchases" component={FirstPurchases} />
                            <Route exact path="/custom_conversions" component={CustomConversions} />
                            <Route exact path="/traffic_sources" component={TrafficSources} />
                            <Route exact path="/user" component={UserDetail} />
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>
                </Provider>
            </IntlProvider>
        )
    }
}

export default App
