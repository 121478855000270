import React from 'react'
import { withRouter } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import {Col, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const CustomConversionsForm = (props) => {
    return (

        <Form className="home-form">
            <Form.Row className="align-items-center">
                <Col xs="auto">
                    <Button type="button" className="mb-2" onClick={e => props.getStats()}>Actualizar <FontAwesomeIcon className="icon-calendar" icon={faSearch} /></Button>
                </Col>
            </Form.Row>
        </Form>

    )
}

export default withRouter(CustomConversionsForm)
