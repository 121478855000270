import React from 'react';
import { withRouter } from 'react-router-dom';

//Bootstrap
import { Table } from 'react-bootstrap';

// Methods
import { useSortableData, formatPrice, formatNumber } from '../../libs/methods';

//style
import "./index.scss";

const ConversionsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th className="sortable"><span onClick={() => requestSort('campaignName')}
                        className={getClassNamesFor('campaignName')}>Campaign Name</span></th>
                    <th className="sortable"><span onClick={() => requestSort('revenue')}
                        className={getClassNamesFor('revenue')}>Revenue</span></th>
                    <th className="sortable"><span onClick={() => requestSort('cost')}
                        className={getClassNamesFor('cost')}>Cost</span></th>
                    <th className="sortable"><span onClick={() => requestSort('profit')}
                        className={getClassNamesFor('profit')}>Profit</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalSOIConv')}
                        className={getClassNamesFor('totalSOIConv')}>SOI Conversions</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalSalesConv')}
                        className={getClassNamesFor('totalSalesConv')}>Sales Conversions</span></th>
                    <th className="sortable"><span onClick={() => requestSort('cpa')}
                        className={getClassNamesFor('cpa')}>CPA</span></th>
                    <th className="sortable"><span onClick={() => requestSort('cpl')}
                        className={getClassNamesFor('cpl')}>CPL</span></th>
                    <th className="sortable"><span onClick={() => requestSort('cpl')}
                        className={getClassNamesFor('cpa1')}>CPA1</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalFirst')}
                        className={getClassNamesFor('totalFirst')}>First Purchases</span></th>
                    <th className="sortable"><span onClick={() => requestSort('amountFirst')}
                        className={getClassNamesFor('amountFirst')}>Amount First Purchases</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalRebilling')}
                        className={getClassNamesFor('totalRebilling')}>Rebilling</span></th>
                    <th className="sortable"><span onClick={() => requestSort('amountRebilling')}
                        className={getClassNamesFor('amountRebilling')}>Amount Rebilling</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPAQ_100Conv')}
                        className={getClassNamesFor('totalPAQ_100Conv')}>PAQ_100</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPAQ_369Conv')}
                        className={getClassNamesFor('totalPAQ_369Conv')}>PAQ_369</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPAQ_709Conv')}
                        className={getClassNamesFor('totalPAQ_709Conv')}>PAQ_709</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPAQ_1034Conv')}
                        className={getClassNamesFor('totalPAQ_1034Conv')}>PAQ_1034</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPAQ_1723Conv')}
                        className={getClassNamesFor('totalPAQ_1723Conv')}>PAQ_1723</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPAQ_80Conv')}
                        className={getClassNamesFor('totalPAQ_80Conv')}>PAQ_80</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalDBConv')}
                        className={getClassNamesFor('totalDBConv')}>DB</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalSMSConv')}
                        className={getClassNamesFor('totalSMSConv')}>SMS</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalVisaConv')}
                        className={getClassNamesFor('totalVisaConv')}>Visa</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalPhoneConv')}
                        className={getClassNamesFor('totalPhoneConv')}>Phone</span></th>

                </tr>
            </thead>
            <tbody>
                { items.length > 0 &&
                    <>
                        { items.map((item, index) => {
                            return (
                                <tr key={index} className={((index+1)%2)?"impar":"par"}>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ item.campaignName }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatPrice(item.revenue) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatPrice(item.cost) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatPrice(item.profit) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ item.totalSOIConv }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ item.totalSalesConv }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.cpa) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.cpl) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.cpa1) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalFirst) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatPrice(item.amountFirst) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalRebilling) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatPrice(item.amountRebilling) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPAQ_100Conv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPAQ_369Conv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPAQ_709Conv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPAQ_1034Conv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPAQ_1723Conv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPAQ_80Conv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalDBConv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalSMSConv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalVisaConv) }</small></td>
                                    <td className="handed" onClick={e => props.history.push(`/campaigns/detail/${item.campaignId}`)}><small>{ formatNumber(item.totalPhoneConv) }</small></td>
                                </tr>
                            )
                        }) }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default withRouter(ConversionsTable)
