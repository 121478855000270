const initialState = {
    loading: false
  };

  // auth reducer
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
  export default (state = initialState, action) => {
    switch (action.type) {
      case "SET_LOADING":
        return Object.assign({}, state, {
            loading: action.loading
        });
      default:
        return state;

    }
  };
