import React from 'react'

//translate
import translate from '../../i18n/translate'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

//style
import './index.scss'


const Pagination = (props) => {

    let pagesNumber = []
    if(props.pager) for( let i = 1; i <= props.pager.pages; i++) { pagesNumber.push(i)}
    return (
        <div className="paginate-content">
           {
           (!props.item && props.pager) &&
                <div className="items">
                    {/*translate('default-paginate-items')*/}
                    {/*<select className="select-items" onChange={props.handleChangeNumberOfColum}>
                        <option value="10" selected={props.pager.items === '10'}>10</option>
                        <option value="25" selected={props.pager.items === '25'}>25</option>
                        <option value="50" selected={props.pager.items === '50'}>50</option>
                        <option value="100" selected={props.pager.items === '100'}>100</option>
                        <option value="150" selected={props.pager.items === '150'}>150</option>
                    </select>*/}
                </div>
            }
            {
                props.total &&
                <div className="number-page">Total Items {props.total}</div>
            }
            {/*
                props.pager &&
                <div className="number-page">
                    <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-2" onClick={ e => props.prevPage(props.pager.page)} />
                    <select className="select-pages" onChange={props.paginate}>
                        {
                            pagesNumber.length > 0 &&
                                pagesNumber.map(number => {
                                    if(parseInt(props.pager.page) === number) {
                                        return <option key={number} value={number} selected>{number}</option>
                                    } else {
                                        return <option key={number} value={number}>{number}</option>
                                    }
                                })
                        }
                    </select>
                    <FontAwesomeIcon icon={faChevronCircleRight} className="mr-2" onClick={ e => props.nextPage(props.pager.page)} />
                </div>
            */}
        </div>
    );
}
Pagination.defaultProps = {
    item: false,
};

export default Pagination;
