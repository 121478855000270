import axios from "axios";
import { debugData } from '../libs/methods'


const tokenExpired = (message) => {
    if(message === "Unauthorized - Token not exists or expired") window.location.reload();
}

export async function userLogin(email, password) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/login`, {
            email ,
            password,
        }).then(res => {
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}

export async function checkusertoken(token) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/checkusertoken`, { token }).then(res => {
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}

export async function logout(token) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/logout`, {
            token,
        }).then(res => {
            if(res.data.status === 'Failed') tokenExpired(res.data.message)
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}

export async function sendEmailPassword(email, env) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/requestpass`, {
            email ,
            env,
        }).then(res => {
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}

export async function recovery(new_password, token) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/recovery`, {
            new_password,
            token,
        }).then(res => {
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}

export async function changExpiredPassword(old_pwd, pwd, token) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/changepassword`, {
            old_pwd,
            pwd,
            token,
        }).then(res => {
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}


export async function getContractApi(token, id_contract) {
    return new Promise((response, error) => {
        axios.post(`${debugData()}/affiliate/getcontract`, {
            id_contract,
            token,
        }, { responseType: 'blob', } ).then(res => {
            if(res.data.status === 'Failed') tokenExpired(res.data.message)
            response(res);
        }).catch(err => {
            console.log(err)
        });
    });
}

export async function acceptContractApi(auth, dispatch) {
    axios.post(`${debugData()}/affiliate/acceptcontract`, {
        id_contract: auth.id_contract,
        token: auth.token,
    }).then(res => {
        if(res.data.status !== "Failed") {
            auth.id_contract = '0'
            dispatch({type: 'SET_AUTH', auth: { auth: auth, logged: true }})
        } else {
            tokenExpired(res.data.message)
        }
    });
}
