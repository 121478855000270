import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

//actions
import { checkusertoken } from '../actions/user';

//libs
import { getAuth, removeAuth } from './cookies';

//components
import Loading from '../components/loading'


const IsAuth = (props) => (WrappedComponent) => {
  return class IsAuth extends Component {
    constructor (props) {
        super(props)
        this.state = {
            auth: null
        }
    }
    componentDidMount(){
      const auth = getAuth()
      if(!this.props.logged) {
        if(auth) {
            checkusertoken(auth.token).then(res => {
              if(res.data.status === "Failed") {
                removeAuth()
                this.setState({ auth: false})
              } else {
                this.props.dispatch({type: 'SET_AUTH', auth: { auth: res.data.message, logged: true }})
                this.setState({ auth: true})
              }
          })
        } else {
            this.setState({ auth: false})
        }
      } else {
        this.setState({ auth: true})
      }
    }

    render() {
      if(this.state.auth === null) {
        return <Loading />
      } else if(this.state.auth) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to='/login' />;
      }
    }
  };
}
export default IsAuth
