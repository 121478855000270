import request from "./api-service";

function getList(token, filters) {
  return request({
    url: `/campaigns/list`,
    method: "POST",
    data: {
        token: token,
        filters: filters
    },
  });
}

function getAll(token) {
  return request({
    url: `/campaigns/all`,
    method: "POST",
    data: {
        token: token
    },
  });
}

const CampaignsService = {
  getList,
  getAll
};

export default CampaignsService;
