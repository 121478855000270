import request from "./api-service";

function getList(token, filters) {
    return request({
        url: `/user_803/list`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}


const UserProfile803Service = {
    getList
};

export default UserProfile803Service;