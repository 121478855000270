import React from 'react'
import { withRouter } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import {Col, Form, InputGroup, Button } from 'react-bootstrap';

//style
import "./index.scss";

const UserForm = (props) => {
    console.log(props.data);
    return (

        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} md="3" controlId="validationFormik02">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="USERNAME"
                value={props.data.USERNAME}
                onChange={props.handleOnChange}
              />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  aria-describedby="inputGroupPrepend"
                  name="EMAIL"
                  value={props.data.EMAIL}
                  onChange={props.handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="validationFormik03">
              <Form.Label>Created</Form.Label>
              <Form.Control
                type="text"
                placeholder="Created At"
                value={props.data.CREATED_AT}
                disabled
              />

              <Form.Control.Feedback type="invalid">
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik04">
              <Form.Label>Updated</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                value={props.data.UPDATED_AT}
                disabled
              />
              <Form.Control.Feedback type="invalid">

              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button type="button" onClick={ e => props.updateData(e) }>Actualizar <FontAwesomeIcon icon={faUserEdit} /></Button>
        </Form>

    )
}

export default withRouter(UserForm)
