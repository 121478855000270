import React, { Component } from "react";
import CampaignsService from "../../services/campaigns-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { Breadcrumb } from 'react-bootstrap';

import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Pagination from '../../components/pagination'
import Form from './form'
import Table from './table'


class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
        filters: {
            search: '',
            pager: {
                page: '1',
                items: '9999'
            }
        },
        error: false,
        showAlert: false,
        alertText: '',
        alertSeverity: 'info', // error, warning, info, success
        data: null,
    };
  }

  componentDidMount() {
      this.getStats();
  }

  getStats = (e) => {
      this.setState({
          data: null,
      });
      this.props.dispatch({ type: 'SET_LOADING', loading: true })
      CampaignsService
          .getList( getAuth().token, this.state.filters )
          .then(response => {
              if(response.data.status !== "Failed") {
                  this.setState({
                      data: response.data.message,
                  })
              } else {
                  this.setState({
                      showAlert: true,
                      alertText: response.data.message,
                      error: true,
                      alertSeverity: 'error' // error, warning, info, success
                  });
              }
      })
      .catch(error => {
          if (error.message === 'Unauthorized IP, or Origin') {
              this.setState({
                  showAlert: true,
                  alertText: error.message,
                  error: true,
                  alertSeverity: 'info' // error, warning, info, success
              });
          }
      })
      .then(res => {
          this.props.dispatch({ type: 'SET_LOADING', loading: false })
      });
    //this.logger = new RichLogger("Login");
  }
  handleOnChange = (e) => this.setState({ search: e.target.value })

  search = (e) => {
      e.preventDefault()
      const { filters, search } = this.state
      filters.search = search
      filters.pager.page = 1
      this.getStats();
  }

  handleChangeDate = (date) => {
      this.setState({
          filters: {
              start_date: date,
          },
          date: date
      })
  }

  paginate = (e) => {
      const { filters } = this.state
      filters.pager.page = e.target.value
      this.getStats()
  }

  handleChangeNumberOfColum = (e) => {
      const { filters } = this.state
      filters.pager.items = e.target.value
      filters.pager.page = 1
      this.getStats()
  }

  nextPage = (page) => {
      const { filters } = this.state
      page++
      if(page <= this.state.data.pager.pages) {
          filters.pager.page = page
          this.getStats()
      }
  }

  prevPage = (page) => {
      const { filters } = this.state
      page--
      if(page > 0) {
          filters.pager.page = page
          this.getStats()
      }
  }
  render() {
    return (
        <Layout>
            <Breadcrumb>
                <NavLink exact={true} activeClassName='active' to="/campaigns" role="button">
                    <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                    Campaigns
                </NavLink>
            </Breadcrumb>
            <Form
                handleOnChange={this.handleOnChange}
                handleChangeDate={this.handleChangeDate}
                date={this.state.date}
                getStats={this.getStats}
                search={this.search}
            />
            <hr />

            {
              (isEmpty(this.state.data) || this.props.loading) &&
              <Loading />
            }
            {
                (!isEmpty(this.state.data)) &&
                <React.Fragment>
                    <Table data={this.state.data.results} />
                    <Pagination
                        handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                        pager={this.state.data.pager}
                        total={this.state.data.total}
                        paginate={this.paginate}
                        prevPage={this.prevPage}
                        nextPage={this.nextPage}
                    />
                </React.Fragment>
            }
        </Layout>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(Campaigns))
