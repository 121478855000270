import { withRouter, Link } from 'react-router-dom';
// Methods
import { useSortableData } from '../../libs/methods';

//Bootstrap
import { Table } from 'react-bootstrap';

//style
import "./index.scss";

const CampaignsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (

        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th>Traffic Source Name</th>
                    <th onClick={() => requestSort('name')}
                        className={getClassNamesFor('name')}>Name</th>
                    <th>URL</th>
                </tr>
            </thead>
            <tbody>
                { items.length > 0 &&
                    <>
                        { items.map((item, index) => {
                            return (
                                <tr key={index} className={((index+1)%2)?"impar":"par"}>
                                    <td><small>{item.ts[0].name}</small></td>
                                    <td><small>{item.name}</small></td>
                                    <td><small><Link to={{ pathname: item.url }} role="button" target="_blank" rel="noopener noreferrer">LINK</Link></small></td>

                                </tr>
                            )
                        }) }
                    </>
                }
            </tbody>
        </Table>

    )
}

export default withRouter(CampaignsTable)
