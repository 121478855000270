import request from "./api-service";

function getCustomConversions(token, filters) {
    return request({
        url: `/custom_conversions/list`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}

const CustomConversionsService = {
    getCustomConversions
};

export default CustomConversionsService;
