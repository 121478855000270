import { Component } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
// API Service
import CostsService from "../../services/costs-service";
//libs
import { getAuth } from "../../libs/cookies";

import Loading from '../../components/loading'

//Bootstrap
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

class CostModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            costData: {
                cost: null,
                old_value: this.props.item.cost,
                user: this.props.auth.username
            },
            filters: {
                date: this.props.item.fecha_str,
                id: this.props.item._id['$oid'],
            }
        };
    }

    handleSaveCost = async (item) => {
        const { costData, filters } = this.state;
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CostsService.updateCosts( getAuth().token, costData, filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({ data: response.data.message,})
            } else {
                /*this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });*/
            }
        }).catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                /*this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });*/
            }
        }).then(response => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
            this.props.handleClose()
        })
    }

    handleCostOnChange = (e) => {
        const { value } = e.target
        const { costData } = this.state
        this.setState({
            costData: {
                ...costData,
                cost: value
            }
        })
    }

    render() {

        return (
            <>
                { (this.props.loading) &&
                    <Loading />
                }
                { !(this.props.loading) &&
                    <Modal show={this.props.show} onHide={this.props.handleClose} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.item.fecha_str} - {this.props.item.campaignName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column>New Cost</Form.Label>
                                <Col>
                                    <Form.Control name="cost" type="number" placeholder="Cost" onChange={this.handleCostOnChange} />
                                </Col>
                            </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.props.handleClose}>Close</Button>
                            <Button variant="primary" onClick={ e => this.handleSaveCost(this.props.item) }>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(CostModal))
