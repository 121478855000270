import React from 'react';
import { getDebugCookie } from "./cookies";

export const formatPrice = (price) => {
    if(price) {
        return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR",}).format(parseFloat(replaceComa(price)))
    } else {
        return  new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR",}).format(parseFloat(0))
    }
}

export const formatNumber = (price) => {
    if(price) {
        return new Intl.NumberFormat("de-DE").format(parseFloat(replaceComa(price)).toFixed(2))
    } else {
        return  new Intl.NumberFormat("de-DE").format(parseFloat(0).toFixed(2)) // toFixed -> con 2 decimales
    }
}

export const sum = (value1, value2) => {
    const result = parseFloat(replaceComa(value1)) + parseFloat(replaceComa(value2))
    return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR",}).format(result)
}

export const click = (id) => document.getElementById(id).click()

export const replaceComa = (value) => {
    if(Number.isInteger(value)) {
        return value
    } else {
        value = '' + value;
        return value.replace(',', '')
    }
}

export const replacePoint = (value) => {
    value = '' + value;
    return value.replace('.', ',');
}


export const dateFormat = (data) => {
    let dd = String(data.getDate()).padStart(2, '0');
    let mm = String(data.getMonth() + 1).padStart(2, '0');
    let yyyy = data.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
}

export const debugData = () => {

    const devCookie = getDebugCookie();
    let baseHref = process.env.REACT_APP_API_URL;

    if( typeof devCookie !== 'undefined' ){
      if( devCookie !== undefined && devCookie !== null && devCookie !== "default" ){
        baseHref = devCookie;
      }
    }

    return baseHref;
}

export const checkIban = (acountNumber) => {
    const CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };
    const iban = String(acountNumber).toUpperCase().replace(/[^A-Z0-9]/g, '')
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) return false
}

export const langFormar = (lang) => {
    if(!lang) return 'es';
    if(process.env.REACT_APP_LANGUAGES.split(',').indexOf(lang.slice(0,2)) > -1 ){
        return lang.slice(0,2);
    }else{
        return 'es';
    }
}

export const isEmpty = (value) => {
     const type = typeof val;
     if ((value !== null && type === 'object') || type === 'function') {
       const properties = Object.keys(value);
        if (properties.length === 0 || properties.size === 0) {
          return true;
        }
      }
      return !value;
}

export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
    const sortedItems = React.useMemo(() => {

        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }

                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'descending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'descending'
        ) {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};
