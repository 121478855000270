import React from 'react';
import { withRouter } from 'react-router-dom';

//Bootstrap
import { Table, Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Methods
import { useSortableData, formatPrice, formatNumber } from '../../libs/methods';

//style
import "./index.scss";

const ConversionsTable = (props) => {
    let balanceClass = 'table-success';
    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th className="sortable"><span onClick={() => requestSort('campaignName')}
                        className={getClassNamesFor('campaignName')}>Campaign Name</span></th>

                    <th className="sortable"><span onClick={() => requestSort('totalSpent')}
                        className={getClassNamesFor('totalSpent')}>Revenue</span></th>

                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Cost</Tooltip>}>
                        <th className="sortable"><span onClick={() => requestSort('totalCost')} className={getClassNamesFor('totalCost')}>Cost</span></th>
                    </OverlayTrigger>

                    <th className="sortable"><span onClick={() => requestSort('balance')} className={getClassNamesFor('balance')}>Balance</span></th>

                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}>
                    <th className="sortable"><span onClick={() => requestSort('CPA')} className={getClassNamesFor('CPA')}>CPA</span></th>
                    </OverlayTrigger>

                    <th className="sortable"><span onClick={() => requestSort('totalCustomers')} className={getClassNamesFor('totalCustomers')}>Customers</span></th>
                    <th className="sortable"><span onClick={() => requestSort('totalOrders')} className={getClassNamesFor('totalOrders')}>Orders</span></th>

                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}>
                    <th className="sortable"><span onClick={() => requestSort('AOF')} className={getClassNamesFor('AOF')}>AOF</span></th>                    
                    </OverlayTrigger>

                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}>
                    <th className="sortable"><span onClick={() => requestSort('AOS')} className={getClassNamesFor('AOS')}>*AOS</span></th>
                    </OverlayTrigger>

                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}>
                    <th className="sortable"><span onClick={() => requestSort('ACV')} className={getClassNamesFor('ACV')}>ACV</span></th>
                    </OverlayTrigger>
                    
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Lifespan</Tooltip>}>
                    <th className="sortable"><span onClick={() => requestSort('CLV')} className={getClassNamesFor('CLV')}>ACL</span></th>
                    </OverlayTrigger>
                    
                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Customer LifeTime Value Gross Margin</Tooltip>}>
                    <th className="sortable"><span onClick={() => requestSort('CLVGMC')} className={getClassNamesFor('CLVGMC')}>CLVGMC</span></th>
                    </OverlayTrigger> */}
                </tr>
            </thead>
            <tbody>
                { items.length > 0 &&
                    <>
                        { items.map((item, index) => {

                             if (item.balance < 0){
                                balanceClass = 'table-danger';
                            } else if (item.balance > 0) {
                                balanceClass = 'table-success';
                            } else {
                                balanceClass = '';
                            }

                            if(item.break_event > 0 && props.dateRangeAlways){
                                var break_event = " ("+item.break_event+")";
                            }else{
                                var break_event = "";
                            }

                            return (
                                <tr key={index} className={((index+1)%2)?"impar":"par",balanceClass}>
                                    <td><small>{ item.campaignName }</small></td>
                                    <td>{ formatPrice(item.totalSpent) }</td>
                                    <td>{ formatPrice(item.totalCost) }</td>
                                    <td> { formatPrice(item.balance) } {break_event}</td>
                                    <td>{ formatPrice(item.CPA) }</td>
                                    <td>{ formatNumber(item.totalCustomers) }</td>
                                    <td>{ formatNumber(item.totalOrders) }</td>
                                    <td>{ formatNumber(item.AOF) }</td>
                                    <td>{ formatNumber(item.AOS) }</td>
                                    <td>{ formatPrice(item.ACV) }</td>
                                    <td>{ formatNumber(item.ACL) } days</td>
                                    {/* <td>{ formatPrice(item.CLVGMC) }</td> */}
                                </tr>
                            )
                        }) }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default withRouter(ConversionsTable)
