import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Table } from 'react-bootstrap';

// Methods
import { useSortableData } from '../../libs/methods';

//style
import "./index.scss";

const CustomConversionsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th className="sortable"><span onClick={() => requestSort('index')}
                        className={getClassNamesFor('index')}>Index</span>
                    </th>
                    <th className="sortable"><span onClick={() => requestSort('name')}
                        className={getClassNamesFor('name')}>Name</span>
                    </th>
                    <th>Include in Conversions</th>
                    <th>Include in Revenue</th>
                    <th>Include in Cost</th>
                </tr>
            </thead>
            <tbody>
                { items.map((item) => (
                    <tr key={item.index} className={((item.index+1)%2)?"impar":"par"}>
                        <td>{item.index}</td>
                        <td>{item.name}</td>
                        <td>
                            { item.includeInConversions
                                ? <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                : <FontAwesomeIcon icon={faTimes} className="mr-2" />
                            }
                        </td>
                        <td>
                            { item.includeInRevenue
                                ? <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                : <FontAwesomeIcon icon={faTimes} className="mr-2" />
                            }
                        </td>
                    <td>
                        { item.includeInCost
                            ? <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            : <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        }
                    </td>
                    </tr>
            ))}
      </tbody>
    </Table>
  );
};

export default withRouter(CustomConversionsTable)
