import React, { Component } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//style
import "./index.scss";
//actions
import { logout } from '../../actions/user';

import isAuth from '../../libs/isAuth'
//libs
import { removeAuth } from '../../libs/cookies';
import SideBar from "../sidebar/SideBar";
import Content from "../content/Content";

class Layout extends Component {
    constructor (props) {
        super(props)
        this.state = {
            headerHeight: 0,
            footerHeight: 0,
            showMenu: true,
            showSidebar: true,
            showUserMenu: true,
            isOpen: false,
            isMobile: true
        }
        this.previousWidth = -1;
    }

    updateWidth() {
      const width = window.innerWidth;
      const widthLimit = 576;
      const isMobile = width <= widthLimit;
      const wasMobile = this.previousWidth <= widthLimit;

      if (isMobile !== wasMobile) {
        this.setState({
          isOpen: !isMobile
        });
      }

      this.previousWidth = width;
    }

    /**
     * Add event listener
     */
    componentDidMount() {
      this.updateWidth();
      window.addEventListener("resize", this.updateWidth.bind(this));
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWidth.bind(this));
    }

    toggle = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };

    logoutUser = () => {
        if(window.confirm("¿Cerrar sesión?")){
            logout(this.props.auth.token).then(res => {
                if(res.status !== "Failed") {
                    removeAuth();
                    this.props.history.push("/login")
                } else { this.setState({ errorEamil: true })}
            })
        }
    }
  render() {
    return (
        <div className="wrapper">
            <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
            <Content
                toggle={this.toggle}
                isOpen={this.state.isOpen}
                content={this.props.children}
                logoutUser={this.logoutUser}
            />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        logged: state.auth.logged,
    }
}

export default withRouter(connect(mapStateToProps)((isAuth()(Layout))))
