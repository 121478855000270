import React, { Component } from "react";
import CostsService from "../../services/costs-service";
import CampaignsService from "../../services/campaigns-service";
import TFService from "../../services/traffic-sources-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty, formatPrice, formatNumber } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Row, Badge, Col, Card, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchDollar, faBan } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Pagination from '../../components/pagination'
import TableVoluum from './tableVoluum'
import FormVoluum from './formVoluum'

import TableAdwords from './tableAdwords'
import FormAdwords from './formAdwords'

class Cost extends Component {
    constructor(props) {
        super(props);
        //var tempDate = new Date();
        var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
        //let prevStartDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDay());
        this.state = {
            filters: {
                start_date: yesterday,
                end_date: yesterday,
                campaignId: '',
                trafficSourceId: '',
                pager: { page: '1', items: '9999' }
            },
            filters_adwords: {
                start_date: yesterday,
                end_date: yesterday,
                campaignId: '',
                pager: { page: '1', items: '9999' }
            },
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info', // error, warning, info, success
            data: null,
            data_adwords: null,
            campaignData: { results: null },
            trafficSourcesData: { results: null },
            selectCampaign: false
        };
    }
    componentDidMount() {
        this.getStats();
        this.getTrafficSources();
        this.getAdwordsCampaigns();
    }

    getAdwordsCampaigns = () => {

        this.setState({ data_adwords: null });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CostsService.getCostsAdwords( getAuth().token, this.state.filters_adwords )
            .then(response => {
                if(response.data.status !== "Failed") {
                    this.setState({ data_adwords: response.data.message });
                } else {
                    this.setState({
                        showAlert: true,
                        alertText: response.data.message,
                        error: true,
                        alertSeverity: 'error' // error, warning, info, success
                    });
                }
        })
        .catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        })
        .then(res => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false });
        });
      //this.logger = new RichLogger("Login");
    }

    getStats = (e) => {
        this.setState({ data: null });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CostsService.getCosts( getAuth().token, this.state.filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({ data: response.data.message });
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        }).catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        }).then(response => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false });
        })
        //this.logger = new RichLogger("Login");
    }

    getCampaigns = (filters) => {
        this.setState({
            campaignData: { results: null},
        });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        CampaignsService.getList( getAuth().token, filters )
            .then(response => {
                if(response.data.status !== "Failed") {
                    this.setState({
                        campaignData: response.data.message,
                    })
                } else {
                    this.setState({
                        showAlert: true,
                        alertText: response.data.message,
                        error: true,
                        alertSeverity: 'error' // error, warning, info, success
                    });
                }
        })
        .catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        })
        .then(res => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        });
      //this.logger = new RichLogger("Login");
    }

    getTrafficSources = (e) => {
        this.setState({
            trafficSourcesData: { results: null},
        });
        let filters = {
            pager: {
                page: '1',
                items: '9999'
            }
        };
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        TFService.getList( getAuth().token, filters ).then(response => {
            if(response.data.status !== "Failed") {
                this.setState({
                    trafficSourcesData: response.data.message,
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertText: response.data.message,
                    error: true,
                    alertSeverity: 'error' // error, warning, info, success
                });
            }
        })
        .catch(error => {
            if (error.message === 'Unauthorized IP, or Origin') {
                this.setState({
                    showAlert: true,
                    alertText: error.message,
                    error: true,
                    alertSeverity: 'info' // error, warning, info, success
                });
            }
        })
        .then(res => {
            this.props.dispatch({ type: 'SET_LOADING', loading: false })
        });
      //this.logger = new RichLogger("Login");
    }

    handleOnChange = (e) => {
        this.setState({
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info'
        })
    }

    handleOnTFChange = (e) => {
        const { filters } = this.state
        if (e.target.value){
            this.setState({
                selectCampaign: true,
                filters: {
                    ...filters,
                    trafficSourceId: e.target.value,
                    search: e.target.value
                }
            });
            let filtersCampaigns = {
                pager: {
                    page: '1',
                    items: '9999',
                },
                search: e.target.value
            };
            // get Campaigns
            this.getCampaigns(filtersCampaigns)
        } else {
            this.setState({
                selectCampaign: false,
                filters: {
                    ...filters,
                    trafficSourceId: '',
                    campaignId: ''
                }
            });
        }
    }

    handleOnCampaignChange = (e) => {
        const { filters } = this.state;
        if (e.target.value !== '') {
            this.setState({
                filters: {
                    ...filters,
                    campaignId: e.target.value
                }
            });
        } else {
            this.setState({
                filters: {
                    ...filters,
                    campaignId: ''
                }
            });
        }
    }


    handleChangeDate = (dates) => {
        const [start_date, end_date] = dates;
        const { filters, filters_adwords } = this.state
        this.setState({
            filters: {
                ...filters,
                start_date: start_date,
                end_date: end_date,
            },
            filters_adwords: {
                ...filters_adwords,
                start_date: start_date,
                end_date: end_date,
            }
        })
    }

    handleSetStartDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                start_date: date
            },
        });
    }

    paginate = (e) => {
        const { filters } = this.state
        filters.pager.page = e.target.value
        this.getStats()
    }

    handleChangeNumberOfColum = (e) => {
        const { filters } = this.state
        filters.pager.items = e.target.value
        filters.pager.page = 1
        this.getStats()
    }

    nextPage = (page) => {
        const { filters } = this.state
        page++
        if(page <= this.state.data.pager.pages) {
            filters.pager.page = page
            this.getStats()
        }
    }

    prevPage = (page) => {
        const { filters } = this.state
        page--
        if(page > 0) {
            filters.pager.page = page
            this.getStats()
        }
    }

    handleChangeRang = (e) => {
        const { filters, filters_adwords } = this.state
        const { value } = e.target
        switch(value){
            case '1':
                let yesteday = new Date(Date.now() - 86400000);
                this.setState({
                    filters: {
                        ...filters,
                        'start_date': yesteday,
                        'end_date': yesteday
                    }, filters_adwords: {
                        ...filters_adwords,
                        'start_date': yesteday,
                        'end_date': yesteday
                    }
                })
                break
            case '2':
                this.setState({
                    filters: {
                        ...filters,
                        'start_date': new Date(new Date().setDate(new Date().getDate() - 2)),
                        'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    }, filters_adwords: {
                        ...filters_adwords,
                        'start_date': new Date(new Date().setDate(new Date().getDate() - 2)),
                        'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    }
                })
                break
            case '3':
                let last7days = new Date(new Date().setDate(new Date().getDate() - 7));
                this.setState({
                    filters: {
                      ...filters,
                      'start_date': last7days,
                      'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                  }, filters_adwords: {
                      ...filters_adwords,
                      'start_date': last7days,
                      'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                  }
                  })
                  break
            case '4':
                let last30days = new Date(new Date().setDate(new Date().getDate() - 30));
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': last30days,
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                }, filters_adwords: {
                    ...filters_adwords,
                    'start_date': last30days,
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                }
                })
                break
            case '5':
                let currenMonth = new Date()
                let firstDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth(), 1);
                let lastDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth() + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfMonth,
                    'end_date': lastDayOfMonth
                }, filters_adwords: {
                    ...filters_adwords,
                    'start_date': firstDayOfMonth,
                    'end_date': lastDayOfMonth
                }
                })
                break
            case '6':
                let lastMonth = new Date()
                let firstDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1), 1);
                let lastDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1) + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfLastMonth,
                    'end_date': lastDayOfLastMonth
                }, filters_adwords:{
                    ...filters_adwords,
                    'start_date': firstDayOfLastMonth,
                    'end_date': lastDayOfLastMonth
                }
                })
                break
            case '7':
                let firstMonthOfYear = new Date(new Date().getFullYear(), 0, 1);
                let lastMonthOfYear = new Date(new Date().getFullYear(), 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfYear,
                    'end_date': lastMonthOfYear
                }, filters_adwords: {
                    ...filters_adwords,
                    'start_date': firstMonthOfYear,
                    'end_date': lastMonthOfYear
                }
                })
                break
            case '8':
                let firstMonthOfLastYear = new Date(new Date().getFullYear() -1, 0, 1);
                let lastMonthOfLastYear = new Date(new Date().getFullYear() -1, 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfLastYear,
                    'end_date': lastMonthOfLastYear
                }, filters_adwords: {
                    ...filters_adwords,
                    'start_date': firstMonthOfLastYear,
                    'end_date': lastMonthOfLastYear
                }
                })
                break
            default:
        }
    }

    render() {
        const { data, filters, filters_adwords, campaignData, data_adwords, trafficSourcesData } = this.state
        return (
            <Layout>
                <Breadcrumb>
                    <NavLink exact={true} activeClassName='active' to="/campaigns" role="button">
                        <FontAwesomeIcon icon={faSearchDollar} className="mr-2" />Costs
                    </NavLink>
                </Breadcrumb>
                <Tabs defaultActiveKey="voluumCost" id="costTabs">
                  <Tab eventKey="voluumCost" title="Voluum Cost">
                    <div className="pt-2">
                      {
                          <FormVoluum
                              handleOnChange={this.handleOnChange}
                              handleChangeDate={this.handleChangeDate}
                              handleChangeRang={this.handleChangeRang}
                              getStats={this.getStats}
                              campaignData={campaignData.results}
                              trafficSourcesData={trafficSourcesData.results}
                              handleOnTFChange={this.handleOnTFChange}
                              handleOnCampaignChange={this.handleOnCampaignChange}
                              selectCampaign={this.state.selectCampaign}
                              start_date={filters.start_date}
                              end_date={filters.end_date}
                              yesterday={new Date(new Date().setDate(new Date().getDate()-1))}
                          />
                      }

                      <hr />
                      { (this.props.loading) &&
                          <Loading />
                      }
                      {
                          (!isEmpty(data)) &&
                          <>
                              {<Container>
                                  <Row className="justify-content-md-center">
                                      <h2><Badge variant="info" pill>Summary</Badge></h2>
                                  </Row>
                                  <Row className="justify-content-md-center">
                                      <Col md>
                                          <Card className="mb-3">
                                              <Card.Header className="p-2">Cost</Card.Header>
                                              <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatPrice(data.summary.cost) }</Card.Title></Card.Body>
                                          </Card>
                                      </Col>
                                      <Col md>
                                          <Card className="mb-3">
                                              <Card.Header className="p-2">Campaigns</Card.Header>
                                              <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(data.summary.nCampaigns) } </Card.Title></Card.Body>
                                          </Card>
                                      </Col>
                                      <Col md>
                                          <Card className="mb-3">
                                              <Card.Header className="p-2">Traffic Sources</Card.Header>
                                              <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ data.summary.nTrafficSources } </Card.Title></Card.Body>
                                          </Card>
                                      </Col>
                                  </Row>
                              </Container>}
                              <TableVoluum data={data.results} getStats={this.getStats} />
                              <Pagination
                                  handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                                  pager={data.pager}
                                  total={data.total}
                                  paginate={this.paginate}
                                  prevPage={this.prevPage}
                                  nextPage={this.nextPage}
                              />
                          </>
                      }
                      { (isEmpty(data)) &&
                          <Container>
                              <Row className="justify-content-md-center">
                                  <h2><Badge variant="warning" pill> <FontAwesomeIcon icon={faBan} className="mr-2" />Ups, no hay datos. Inténtelo con otros parámetros de búsqueda</Badge></h2>
                              </Row>
                          </Container>
                      }
                      </div>
                  </Tab>
                  {/* ADWORDS */}
                  <Tab eventKey="adwordsCost" title="Adwords Cost">
                    <div className="pt-2">
                    {
                          <FormAdwords
                              handleOnChange={this.handleOnChange}
                              handleChangeDate={this.handleChangeDate}
                              handleChangeRang={this.handleChangeRang}
                              getStats={this.getAdwordsCampaigns}
                              handleOnCampaignChange={this.handleOnCampaignChange}
                              selectCampaign={this.state.selectCampaign}
                              start_date={filters_adwords.start_date}
                              end_date={filters_adwords.end_date}
                              yesterday={new Date(new Date().setDate(new Date().getDate()-1))}
                          />
                      }

                      <hr />
                      { (this.props.loading) &&
                          <Loading />
                      }
                      {
                          (!isEmpty(data_adwords)) &&
                          <>
                              <TableAdwords data={data_adwords.results} getStats={this.getAdwordsCampaigns} />
                              <Pagination
                                  handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                                  pager={data_adwords.pager}
                                  total={data_adwords.total}
                                  paginate={this.paginate}
                                  prevPage={this.prevPage}
                                  nextPage={this.nextPage}
                              />
                          </>
                      }
                      { (isEmpty(data_adwords)) &&
                          <Container>
                              <Row className="justify-content-md-center">
                                  <h2><Badge variant="warning" pill> <FontAwesomeIcon icon={faBan} className="mr-2" />Ups, no hay datos. Inténtelo con otros parámetros de búsqueda</Badge></h2>
                              </Row>
                          </Container>
                      }
                    </div>
                  </Tab>
                </Tabs>

            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(Cost))
