import React from 'react'
import { withRouter } from 'react-router-dom';
import es from 'date-fns/locale/es';
import DatePicker, {registerLocale} from 'react-datepicker'
import { getLang } from '../../libs/cookies';
import { click } from '../../libs/methods';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import {Col, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const AdwordsForm = (props) => {
    registerLocale('es', es)
    let format_start_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.start_date);
    let format_end_date = new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.end_date);
    return (

        <Form className="home-form">
            <Form.Row className="align-items-center">

                <Col xs="auto">
                    <Form.Group><Form.Label>&nbsp;</Form.Label><Form.Control className="handed" onClick={e => click("calendar_date1")} as="text">{ format_start_date } { props.end_date && " al " + format_end_date }</Form.Control></Form.Group>
                </Col>
                {<Col xs="auto">
                    <div className="form-group calendar sp">
                        <Form.Label htmlFor="inlineFormInputName2" srOnly>Fecha</Form.Label>
                        <div className="form-control mt-4 datepickercustom">
                            <DatePicker
                                selected={props.start_date}
                                startDate={props.start_date}
                                endDate={props.end_date}
                                maxDate={props.yesterday}
                                selectsRange
                                onChange={dates => props.handleChangeDate(dates)}
                                locale={getLang()}
                                dateFormat="dd/MM/yyyy"
                                monthsShown={2}
                                id="calendar_date1"
                                placeholder="Seleccione Fecha"
                                popperPlacement="top"
                            />
                        </div>
                        <FontAwesomeIcon
                            className="icon-calendar-cost" icon={faCalendar}
                            style={{ color: '#333' }} onClick={e => click("calendar_date1")}
                        />
                    </div>
                </Col>}

                <Col xs="auto">
                    <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            name="range_date"
                            placeholder="Range Date"
                            onChange={props.handleChangeRang}
                        >
                            <option value="">Select Date Range</option>
                            <option value="1">Yesterday</option>
                            <option value="2">Last 48h</option>
                            <option value="3">Last 7 days</option>
                            <option value="4">Last 30 days</option>
                            <option value="5">This Month</option>
                            <option value="6">Last Month</option>
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Col xs="auto">
                    <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <Button type="button" className="form-control" onClick={e => props.getStats()}>Enviar</Button>
                    </Form.Group>
                </Col>
            </Form.Row>
        </Form>

    )
}

export default withRouter(AdwordsForm)
