import React from 'react'
import { withRouter, Link } from 'react-router-dom';

//Bootstrap
import {Table } from 'react-bootstrap';

//style
import "./index.scss";

const OffersTable = (props) => {
    return (

        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th>Name</th>
                    <th>URL</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.data.length > 0 &&
                        <React.Fragment>
                            {
                                props.data.map((item, index) => {
                                    return (
                                        <tr key={index} className={((index+1)%2)?"impar":"par"}>
                                            <td><small>{item.name}</small></td>
                                            <td><small><Link to={{ pathname: item.url }} role="button" target="_blank" rel="noopener noreferrer">LINK</Link></small></td>
                                        </tr>
                                    )
                                })
                            }
                        </React.Fragment>
                }
            </tbody>
        </Table>

    )
}

export default withRouter(OffersTable)
