import request from "./api-service";

function dashboard(token, filters) {
  return request({
    url: `/dashboard`,
    method: "POST",
    data: {
        token: token,
        filters: filters
    },
  });
}

const HomeService = {
  dashboard
};

export default HomeService;
