import React, { Component } from "react";
import UserService from "../../services/user-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Breadcrumb } from 'react-bootstrap';

import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Form from './form'


class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        error: false,
        showAlert: false,
        alertText: '',
        alertSeverity: 'info', // error, warning, info, success
        userData: null,
    };
  }

  componentDidMount() {
      this.getData();
  }

  getData = (e) => {
      this.setState({ userData: null });
      this.props.dispatch({ type: 'SET_LOADING', loading: true })
      UserService
          .get( getAuth().token )
          .then(response => {
              if(response.data.status !== "Failed") {
                  this.setState({
                      userData: response.data.message.user_data,
                  })
              } else {
                  this.setState({
                      showAlert: true,
                      alertText: response.data.message,
                      error: true,
                      alertSeverity: 'error' // error, warning, info, success
                  });
              }
      })
      .catch(error => {
          if (error.message === 'Unauthorized IP, or Origin') {
              this.setState({
                  showAlert: true,
                  alertText: error.message,
                  error: true,
                  alertSeverity: 'info' // error, warning, info, success
              });
          }
      })
      this.props.dispatch({ type: 'SET_LOADING', loading: false })
    //this.logger = new RichLogger("Login");
  }

  updateData = (e) => {
      this.props.dispatch({ type: 'SET_LOADING', loading: true })
      UserService.update( getAuth().token, this.state.userData )
          .then(response => {
              if(response.data.status === "Failed") {
                  this.setState({
                      showAlert: true,
                      alertText: response.data.message,
                      error: true,
                      alertSeverity: 'error' // error, warning, info, success
                  });
              }
          })
          .catch(error => {
              if (error.message === 'Unauthorized IP, or Origin') {
                  this.setState({
                      showAlert: true,
                      alertText: error.message,
                      error: true,
                      alertSeverity: 'info' // error, warning, info, success
                  });
              }
          })
          .then(res => {
              this.props.dispatch({ type: 'SET_LOADING', loading: false })
          });
  }

  handleOnChange = (e) => {
      const { userData } = this.state
      const { value, name } = e.target
      this.setState({
          userData: {
            ...userData,
            [name]: value
          }
      })
  }

  render() {
    return (
        <Layout>
            <Breadcrumb>
                <NavLink exact={true} activeClassName='active' to="/user" role="button">
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                    User Detail
                </NavLink>
            </Breadcrumb>
            <hr />

            {
              (isEmpty(this.state.userData) || this.props.loading) &&
                <Loading />
            }
            {
                (!isEmpty(this.state.userData)) &&
                    <Form
                        data={this.state.userData}
                        updateData={this.updateData}
                        handleOnChange={this.handleOnChange}
                    />
            }
        </Layout>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(UserDetail))
