import request from "./api-service";

function getList(token, filters) {
    return request({
        url: `/user_profile/list`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}

function getCampaigns(token, filters) {
    return request({
        url: `/user_profile/campaignList`,
        method: "POST",
        data: {
            token: token,
            filters: filters
        },
    });
}


const UserProfileService = {
    getList,
    getCampaigns
};

export default UserProfileService;