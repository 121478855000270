import React from 'react';
import { withRouter } from 'react-router-dom';

//Bootstrap
import { Table, Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Methods
import { formatPrice, formatNumber } from '../../libs/methods';

//style
import "./index.scss";

const ConversionsTable = (props) => {
    const { month, year, days } = props.data;
    let balanceClass = 'table-success';
    return (
        <>
            <Row className="align-items-center">
                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Year</Badge></h2>
                { (year) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Year</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}><th>Revenue</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Cost</Tooltip>}><th>Cost</th></OverlayTrigger>
                                <th>Balance</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}><th>CPA</th></OverlayTrigger>
                                <th>Customers</th>
                                <th>Orders</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}><th>AOF</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}><th>*AOS</th></OverlayTrigger>                                
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}><th>ACV</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Lifespan</Tooltip>}><th>ACL</th></OverlayTrigger>
                                {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Customer LifeTime Value Gross Margin</Tooltip>}><th>CLV Gross Margin</th></OverlayTrigger> */}
                            </tr>
                        </thead>
                        <tbody>
                        { Object.keys(year).map((key, value ) => {
                            if (year[key].balance < 0){
                                balanceClass = 'table-danger';
                            } else if (year[key].balance > 0) {
                                balanceClass = 'table-success';
                            } else {
                                balanceClass = '';
                            }

                            if(year[key].break_event > 0){
                                var break_event = " ("+year[key].break_event+")";
                            }else{
                                var break_event = "";
                            }
                            return (

                                <tr className={balanceClass}>
                                    <td>{key}</td>
                                    <td>{ formatPrice(year[key].totalSpent) }</td>
                                    <td>{ formatPrice(year[key].totalCost) }</td>
                                    <td> { formatPrice(year[key].balance) } {break_event}</td>
                                    <td>{ formatPrice(year[key].CPA) }</td>
                                    <td>{ formatNumber(year[key].totalCustomers) }</td>
                                    <td>{ formatNumber(year[key].totalOrders) }</td>
                                    <td>{ formatNumber(year[key].AOF) }</td>
                                    <td>{ formatPrice(year[key].AOS) }</td>                                    
                                    <td>{ formatPrice(year[key].ACV) }</td>
                                    <td>{ formatNumber(year[key].ACL) } days</td>
                                    {/* <td>{ formatPrice(year[key].CLVGMC) }</td> */}
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                }

                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Month</Badge></h2>
                { (month) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}><th>Revenue</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Cost</Tooltip>}><th>Cost</th></OverlayTrigger>
                                <th>Balance</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}><th>CPA</th></OverlayTrigger>
                                <th>Customers</th>
                                <th>Orders</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}><th>AOF</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}><th>*AOS</th></OverlayTrigger>                                
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}><th>ACV</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Lifespan</Tooltip>}><th>ACL</th></OverlayTrigger>
                                {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Customer LifeTime Value Gross Margin</Tooltip>}><th>CLV Gross Margin</th></OverlayTrigger> */}
                            </tr>
                        </thead>
                        <tbody>
                        { Object.keys(month).map((key1, value ) => {
                            if (month[key1].balance < 0){
                                balanceClass = 'table-danger';
                            } else if (month[key1].balance > 0) {
                                balanceClass = 'table-success';
                            } else {
                                balanceClass = '';
                            }

                            if(month[key1].break_event > 0){
                                var break_event = " ("+month[key1].break_event+")";
                            }else{
                                var break_event = "";
                            }

                            return (
                                <tr className={balanceClass}>
                                    <td>{key1}</td>
                                    <td>{ formatPrice(month[key1].totalSpent) }</td>
                                    <td>{ formatPrice(month[key1].totalCost) }</td>
                                    <td>{ formatPrice(month[key1].balance) } {break_event}</td>
                                    <td>{ formatPrice(month[key1].CPA) }</td>
                                    <td>{ formatNumber(month[key1].totalCustomers) }</td>
                                    <td>{ formatNumber(month[key1].totalOrders) }</td>
                                    <td>{ formatNumber(month[key1].AOF) }</td>
                                    <td>{ formatPrice(month[key1].AOS) }</td>                                    
                                    <td>{ formatPrice(month[key1].ACV) }</td>
                                    <td>{ formatNumber(month[key1].ACL) } days</td>
                                    {/* <td>{ formatPrice(month[key1].CLVGMC) }</td> */}
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                }

                <hr className="infoHr" />
                <h2><Badge variant="info" pill>Day</Badge></h2>
                { (days) &&
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Day</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Spent</Tooltip>}><th>Revenue</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Cost</Tooltip>}><th>Cost</th></OverlayTrigger>
                                <th>Balance</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste por Adquisición</Tooltip>}><th>CPA</th></OverlayTrigger>
                                <th>Customers</th>
                                <th>Orders</th>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Frequency</Tooltip>}><th>AOF</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Orders Size</Tooltip>}><th>*AOS</th></OverlayTrigger>                                
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Value</Tooltip>}><th>ACV</th></OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Customer Lifespan</Tooltip>}><th>ACL</th></OverlayTrigger>
                                {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Customer LifeTime Value Gross Margin</Tooltip>}><th>CLV Gross Margin</th></OverlayTrigger> */}
                            </tr>
                        </thead>
                        <tbody>
                            { Object.keys(days).map((key2, value ) => {
                                if (days[key2].balance < 0){
                                    balanceClass = 'table-danger';
                                } else if (days[key2].balance > 0) {
                                    balanceClass = 'table-success';
                                } else {
                                    balanceClass = '';
                                }

                                if(days[key2].break_event > 0){
                                    var break_event = " ("+days[key2].break_event+")";
                                }else{
                                    var break_event = "";
                                }

                                return (
                                    <tr className={balanceClass}>
                                        <td>{key2}</td>
                                        <td>{ formatPrice(days[key2].totalSpent) }</td>
                                        <td>{ formatPrice(days[key2].totalCost) }</td>
                                        <td>{ formatPrice(days[key2].balance) } {break_event}</td>
                                        <td>{ formatPrice(days[key2].CPA) }</td>
                                        <td>{ formatNumber(days[key2].totalCustomers) }</td>
                                        <td>{ formatNumber(days[key2].totalOrders) }</td>
                                        <td>{ formatNumber(days[key2].AOF) }</td>
                                        <td>{ formatPrice(days[key2].AOS) }</td>                                        
                                        <td>{ formatPrice(days[key2].ACV) }</td>
                                        <td>{ formatNumber(days[key2].ACL) } days</td>
                                        {/* <td>{ formatPrice(days[key2].CLVGMC) }</td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </Row>
        </>
    )
}

export default withRouter(ConversionsTable)
