import { createStore, combineReducers } from 'redux'
import auth from './data/auth'
import loading from './data/loading'

const reducers = combineReducers({
    auth,
    loading,
})

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&  window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
