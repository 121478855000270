import React from 'react';
import { withRouter } from 'react-router-dom';

//Bootstrap
import { Table } from 'react-bootstrap';

// Methods
import { useSortableData, formatPrice, formatNumber } from '../../libs/methods';

//style
import "./index.scss";

const FPTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr className="text-center">
                    <th className="sortable"><span onClick={() => requestSort('date_srt')}
                        className={getClassNamesFor('date_srt')}>Date</span></th>
                    <th className="sortable"><span onClick={() => requestSort('campaignName')}
                        className={getClassNamesFor('campaignName')}>Campaign</span></th>
                    <th className="sortable"><span onClick={() => requestSort('revenue')}
                        className={getClassNamesFor('revenue')}>Traffic Source</span></th>
                    <th className="sortable"><span onClick={() => requestSort('paymentType')}
                        className={getClassNamesFor('paymentType')}>Type</span></th>
                    <th className="sortable"><span onClick={() => requestSort('clickId')}
                        className={getClassNamesFor('clickId')}>Click Id</span></th>
                    <th className="sortable"><span onClick={() => requestSort('amount')}
                        className={getClassNamesFor('amount')}>Amount</span></th>
                    <th className="sortable"><span onClick={() => requestSort('conversionType')}
                        className={getClassNamesFor('conversionType')}>Conversion Type</span></th>
                    <th className="sortable"><span onClick={() => requestSort('firstPurchase')}
                        className={getClassNamesFor('firstPurchase')}>First Purchase</span></th>
                </tr>
            </thead>
            <tbody>
                { items.length > 0 &&
                    <>
                        { items.map((item, index) => {
                            return (
                                <tr key={index} className={((index+1)%2)?"impar":"par"}>
                                    <td><small>{ item.date_str }</small></td>
                                    <td><small>{ item.campaignName }</small></td>
                                    <td><small>{ item.trafficSourceName }</small></td>
                                    <td><small>{ item.paymentType }</small></td>
                                    <td><small>{ item.clickId }</small></td>
                                    <td><small>{ formatPrice(item.amount) }</small></td>
                                    <td><small>{ item.conversionType }</small></td>
                                    <td><small>{ item.firstPurchase }</small></td>
                                </tr>
                            )
                        }) }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default withRouter(FPTable)
