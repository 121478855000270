import React, { Component } from "react";
import HomeService from "../../services/home-service";
//libs
import { getAuth } from "../../libs/cookies";
import { isEmpty } from '../../libs/methods';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';

import { Breadcrumb, Container, Col, Row, Card, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faTable } from "@fortawesome/free-solid-svg-icons";

// LIBS
import { formatPrice, formatNumber, replacePoint } from '../../libs/methods'

// Components
import Layout from '../../components/layout'
import Loading from '../../components/loading'
import Form from './form'
import Info from './info'


class Home extends Component {
    constructor(props) {
        super(props);
        //var tempDate = new Date();
        var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
        //let prevStartDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDay());
        this.state = {
            filters: {
                start_date: yesterday,
                end_date: yesterday,
            },
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info', // error, warning, info, success
            summaryData: null,
            showInfo: false,
            showMonth: false,
            showDay: false,
        };
    }

    componentDidMount() {
        this.getStats();
    }

    getStats = (e) => {
        this.setState({
            summaryData: null,
        });
        this.props.dispatch({ type: 'SET_LOADING', loading: true })
        HomeService.dashboard( getAuth().token, this.state.filters )
            .then(response => {
                if(response.data.status !== "Failed") {
                    this.setState({
                        summaryData: response.data.message,
                    })
                } else {
                    this.setState({
                        showAlert: true,
                        alertText: response.data.message,
                        error: true,
                        alertSeverity: 'error' // error, warning, info, success
                    });
                }
            })
            .catch(error => {
                if (error.message === 'Unauthorized IP, or Origin') {
                    this.setState({
                        showAlert: true,
                        alertText: error.message,
                        error: true,
                        alertSeverity: 'info' // error, warning, info, success
                    });
                }
            })
            .then(res => {
                this.props.dispatch({ type: 'SET_LOADING', loading: false })
            });
            //this.logger = new RichLogger("Login");
    }
    handleOnChange = (e) => {
        this.setState({
            error: false,
            showAlert: false,
            alertText: '',
            alertSeverity: 'info'
        })
    }

    handleChangeDate = (dates) => {
        const [start_date, end_date] = dates;
        this.setState({
            filters: {
                start_date: start_date,
                end_date: end_date,
            },
        })
    }

    handleSetStartDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                'start_date': date
            },
        })
    }

    handleSetEndDate = (date) => {
        const { filters } = this.state
        this.setState({
            filters: {
                ...filters,
                'end_date': date
            },
        })
    }
    handleChangeRang = (e) => {
        const { filters } = this.state
        const { value } = e.target
        switch(value){
            case '1':
                let yesteday = new Date(Date.now() - 86400000);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': yesteday,
                    'end_date': yesteday
                    }
                })
                break
            case '2':
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': new Date(new Date().setDate(new Date().getDate() - 2)),
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    }
                })
                break
            case '3':
                let last7days = new Date(new Date().setDate(new Date().getDate() - 7));
                this.setState({
                    filters: {
                      ...filters,
                      'start_date': last7days,
                      'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    },
                  })
                  break
            case '4':
                let last30days = new Date(new Date().setDate(new Date().getDate() - 30));
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': last30days,
                    'end_date': new Date(new Date().setDate(new Date().getDate()-1))
                    },
                })
                break
            case '5':
                let currenMonth = new Date()
                let firstDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth(), 1);
                let lastDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth() + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfMonth,
                    'end_date': lastDayOfMonth
                    }
                })
                break
            case '6':
                let lastMonth = new Date()
                let firstDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1), 1);
                let lastDayOfLastMonth = new Date(lastMonth.getFullYear(), (lastMonth.getMonth() - 1) + 1, 0);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstDayOfLastMonth,
                    'end_date': lastDayOfLastMonth
                    },
                })
                break
            case '7':
                let firstMonthOfYear = new Date(new Date().getFullYear(), 0, 1);
                let lastMonthOfYear = new Date(new Date().getFullYear(), 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfYear,
                    'end_date': lastMonthOfYear
                    },
                })
                break
            case '8':
                let firstMonthOfLastYear = new Date(new Date().getFullYear() -1, 0, 1);
                let lastMonthOfLastYear = new Date(new Date().getFullYear() -1, 11, 31);
                this.setState({
                    filters: {
                    ...filters,
                    'start_date': firstMonthOfLastYear,
                    'end_date': lastMonthOfLastYear
                    },
                })
                break
            default:
        }
    }

    handleShowInfo = (e) => {
        this.setState({
          showInfo: !this.state.showInfo
        });
    }

    handleShowMonth = (e) => {
        this.setState({
          showMonth: !this.state.showMonth
        });
    }

    handleShowDay = (e) => {
        this.setState({
          showDay: !this.state.showDay
        });
    }
    render() {
        const { summaryData, filters, showMonth, showInfo, showDay } = this.state;
        return (
            <Layout>
                <Breadcrumb>
                    <NavLink exact={true} activeClassName='active' to='/' role="button">
                        <FontAwesomeIcon icon={faHome} className="mr-2" />Home
                    </NavLink>
                </Breadcrumb>
                <Form
                    handleOnChange={this.handleOnChange}
                    handleChangeDate={this.handleChangeDate}
                    start_date={filters.start_date}
                    end_date={filters.end_date}
                    getStats={this.getStats}
                    yesterday={new Date(new Date().setDate(new Date().getDate()-1))}
                    handleSetStartDate={this.handleSetStartDate}
                    handleSetEndDate={this.handleSetEndDate}
                    handleChangeRang={this.handleChangeRang}
                />

                { (isEmpty(summaryData) || this.props.loading) &&
                    <Loading />
                }
                { (!isEmpty(summaryData)) &&

                    <Container>
                        <Row className="justify-content-md-center">
                            <h2><Badge variant="info" pill>Total Summary</Badge></h2>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ingresos</Tooltip>}>
                                        <Card.Header className="p-2">Revenue</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatPrice(summaryData.total.revenue) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Total Costs</Tooltip>}>
                                        <Card.Header className="p-2">Cost</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatPrice(summaryData.total.cost) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ganancias</Tooltip>}>
                                        <Card.Header className="p-2">Profit</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatPrice(summaryData.total.profit) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Conversiones de Venta</Tooltip>}>
                                        <Card.Header className="p-2">Sales Conversions</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(summaryData.total.totalSalesConv) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">SOI</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{summaryData.total.totalSOIConv}</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Adquisición</Tooltip>}>
                                        <Card.Header className="p-2">CPA</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ replacePoint(summaryData.total.cpa) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Lead</Tooltip>}>
                                        <Card.Header className="p-2">CPL</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ replacePoint(summaryData.total.cpl) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Coste Por Adquisición Primera Compra</Tooltip>}>
                                        <Card.Header className="p-2">CPA1</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ replacePoint(summaryData.total.cpa1) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / Sales Conversions</Tooltip>}>
                                        <Card.Header className="p-2">ARC </Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(summaryData.total.arc) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Card className="mb-3">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Average Revenue / Lead</Tooltip>}>
                                        <Card.Header className="p-2">ARL</Card.Header>
                                    </OverlayTrigger>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(summaryData.total.arl) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Unique Visits</Card.Header>
                                    <Card.Body className="p-2"><Card.Title className="text-center mb-0">{ formatNumber(summaryData.total.uniqueVisits) }</Card.Title></Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">First Purchases</Card.Header>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(summaryData.total.firstPurchases) }</Card.Title>
                                        <Card.Text className="text-center mb-0">{ formatPrice(summaryData.total.amountFP) }</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card className="mb-3">
                                    <Card.Header className="p-2">Rebilling</Card.Header>
                                    <Card.Body className="p-2">
                                        <Card.Title className="text-center mb-0">{ formatNumber(summaryData.total.rebilling) }</Card.Title>
                                        <Card.Text className="text-center mb-0">{ formatPrice(summaryData.total.amountRebilling) }</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Button className="w-100" variant="success" onClick={ this.handleShowInfo }><FontAwesomeIcon icon={faTable} className="mr-2" /> Desglose</Button>
                        </Row>
                        { (showInfo) &&
                            <Info data={summaryData}
                                showMonth={showMonth}
                                showDay={showDay}
                                handleShowMonth={this.handleShowMonth}
                                handleShowDay={this.handleShowDay}
                            />
                        }
                    </Container>
                }
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
        loading: state.loading.loading,
    }
}

export default withRouter(connect(mapStateToProps)(Home))
