import React from 'react'
import { withRouter } from 'react-router-dom';

//Bootstrap
import {Col, Form, Button } from 'react-bootstrap';

//style
import "./index.scss";

const OffersForm = (props) => {
    return (
        <Form inline className="campaign-form" onSubmit={ e => props.search(e) }>
            <Form.Row className="align-items-center">
                <Col xs="auto">
                    <Form.Group>
                        <Form.Label className="small mb-1" for="inputEmailAddress" srOnly>Buscar</Form.Label>
                        <Form.Control
                            type="text"
                            className={`form-control ${props.error ? "is-invalid" : ""}`}
                            name="search"
                            placeholder="Buscar"
                            onChange={props.handleOnChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Button type="submit" className="">Enviar</Button>
                </Col>
            </Form.Row>
        </Form>

    )
}

export default withRouter(OffersForm)
